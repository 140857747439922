import React from 'react'
import CustomNav from '../components/CustomNav'
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../pages/css/slide.css';
import {Icon} from 'rsuite'

import rise from '../../src/assets/imgs/rise.png'
import buyrent from '../../src/assets/imgs/buyrent.png'
import loca from '../../src/assets/imgs/loca.png'
import expen from '../../src/assets/imgs/expen.png'
import Footer from '../components/Footer';
import video from './../assets/video.mp4';

export default function About() {
  return (
    <div style={{ backgroundColor: '#028599' }}>
        <CustomNav activeKey='about'/>
        
        <section className="section_1">
                <Container className = "section_1_a">
                    <Row>
                        <Col xs={12} sm={12} md={6}  className="sect_1_a">
                            <h1>
                                WELCOME TO BLACKPRINCE-ESTATE-HOUSES
                            </h1>
                            <p>
                            We provide access to rent easily, buy a house easily,buy a plot easily, we also provide easy property management for landlords
                            </p>
                            <Button style={{backgrounColor: '#028599'}}>
                                View properties
                            </Button>

                        </Col>
                        <Col className="illustration" sm={12} md={6}>
                        <video controls width="420px" height="315px">
                          <source src={video} />
                        </video>
                        </Col>
                    </Row>
                </Container>
            </section>
            <section className="section_2">
                <Container>
                    {/* <h2>Content For Start Up Brand Kit </h2> */}
                    <p className="textbrand">
                       OUR SERVICES
                    </p>
                    {/* <p className="deli">Delivery time 14 days</p> */}
                    <Row>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                    <img src={loca}/>
                                    <h6>Find your future home</h6>
                                    <span>We help you find a new home by offering a smart real estate.</span>
                                </div>
                            </div>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                    <img src={expen}/>
                                    <h6>Experienced agents</h6>
                                    <span> Find an agent who knows your market best</span>
                                </div>
                            </div>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                    <img src={buyrent}/>
                                    <h6>Buy or rent homes</h6>
                                    <span> Millions of houses and apartments in your favourite cities</span>
                                </div>
                            </div>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                    <img src={rise}/>
                                    <h6>List your own property</h6>
                                    <span>Advertise your properties to millions of potential customers</span>
                                </div>
                            </div>
                        </Row>
                </Container>
            </section>
            <section className="section_2">
                <Container>
                    {/* <h2>Content For Start Up Brand Kit </h2> */}
                    <p className="textbrand">
                       OUR AGENTS
                    </p>
                    {/* <p className="deli">Delivery time 14 days</p> */}
                    <Row>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                    
                                </div>
                            </div>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                   
                                </div>
                            </div>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                    
                                </div>
                            </div>
                            <div className='col-6 col-sm-4 col-md-3'>
                                <div className='serv container'>
                                   
                                </div>
                            </div>
                        </Row>
                </Container>
            </section>
            <Footer />
    </div>
  )
}
