export function getFromStorage(key) {
    if(!key){
        return null;
    }

    try{
        const valueStr = localStorage.getItem(key);
        if(valueStr){
            return JSON.parse(valueStr);
        }
        return null;
    }catch(err){
        return null;
    }
}

export function setInstorage(key, obj){
    if(!key){
        console.error('Error: Key is missing');
    }

    try{
        localStorage.setItem(key, JSON.stringify(obj));
    }catch(err){
        console.error(err);
    }
}

export function deleteStorage(key){
  if(!key){
    return null;
  }

  try{
    localStorage.removeItem(key);
  }catch(err){
    console.log(err)
  }
}