import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export default function Footer(){
  return(
    <Styles>
      <Container>
        <Row style={{ margin: 0, paddingTop: 70, marginBottom: 100 }}>
          <Col md="4">
            <img src={require('./../assets/logo.png')} style={{ width: '50%', marginBottom: 10 }} />
            <p>Get a house to rent easily,buy a house easily, buy a plot easily with Blackprince-Estate-Houses</p>
          </Col>
          <Col md="4">
            <Row>
              <Col md="6">
                <h6>Help</h6>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Link className="link" to="#">Privacy Policy</Link>
                  <Link className="link" to="#">Contact Support</Link>
                  {/* <Link className="link" to="#">Knowledgebase</Link> */}
                  <Link className="link" to="#">Careers</Link>
                  <Link className="link" to="#">FAQS</Link>
                </div>
              </Col>
              <Col md="6">
                <h6>Links</h6>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <Link className="link" to="#">Contact</Link>
                  <Link className="link" to="#">Create Property</Link>
                  <Link className="link" to="#">My Properties</Link>
                  <Link className="link" to="#">Register</Link>
                  <Link className="link" to="#">Login</Link>
                </div>
              </Col>
            </Row>
          </Col>
          <Col md="4">
            <h6>Newsletter</h6>
            <p>Subscribe to our newsletters for more</p>
            <input type="text" className="inp" placeholder="Email Address" />
            <button className="sbtn">Submit</button>
          </Col>
        </Row>
        <div style={{ width: '100%', borderBottom: '1px solid #999999' }}/>
        <div style={{ textAlign: 'center', padding: 15 }}>Copyright ©2022 All rights reserved</div>
      </Container>
    </Styles>
  )
}

const Styles = styled.div`
  background-color: #105f6c;
  color: #dbdbdb;

  h6{
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 30px;
    text-transform: uppercase;
  }
  .link{
    color: #dbdbdb;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    font-family: Montserrat, sans-serif;
  }
  .inp{
    width: 100%;
    padding: 10px;
    background-color: transparent;
    border: 1px solid #dbdbdb;
    outline: none;
    opacity: 0.7;
    margin-bottom: 20px;
  }
  .sbtn{
    font-size: 14px;
    color: #ffffff;
    background: #067aa9;
    border: none;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    display: inline-block;
    padding: 14px 30px;
  }
`;