import { Navbar, Nav, Icon, Dropdown, InputGroup, Input, Alert } from 'rsuite';
import React, {useState} from 'react'
import { useNavigate } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import styled from 'styled-components';

import logo from './../assets/logo.png';
import { getFromStorage } from "./../utils/Storage";

function CustomNav({activeKey}){

    const navigate = useNavigate();
    const user = getFromStorage('userToken')
    const [text, setText] = useState("")
    
    function handleSelect(eventKey) {
        console.log(eventKey);
        if(eventKey==='home' || eventKey==='about' || eventKey==='login' || eventKey==="register" || eventKey==="my-account" || eventKey==="blog"){
            if(eventKey==='home'){
                navigate('/');
                return;
            }
            navigate(`/${eventKey}`);
        }else{
            navigate(`/property/${eventKey}`);
        }
    }

    const searching = ()=>{
        if(!text){
            Alert.warning("Please enter a property name");
            return;
        }
        if(!user){
          Alert.warning("Please make sure you are signed in");
            return;
        }
        navigate(`/search/${text}`);
    }

    return (
        <Styles style={{ position: 'fixed', top: 0, left: 0, zIndex: 100, width: '100vw', height: 85 }}>
            <Navbar appearance="inverse"  style={{ borderBottom: '1px solid #dbdbdb', backgroundColor: '#028599' }}>
              <Container>
                <Navbar.Header>
                <a href="/" className="navbar-brand logo">
                  <img src={logo} style={{ height: 50, marginRight: 10 }} />
                  <span>Blackprince</span>
                </a>
                </Navbar.Header>
                <Navbar.Body>
                <Nav onSelect={handleSelect} activeKey={activeKey}>
                    <Nav.Item eventKey="home" icon={<Icon icon="home" />}>
                    Home
                    </Nav.Item>
                    <Nav.Item eventKey="about">Company</Nav.Item>
                    {/* <Nav.Item eventKey="blog">Blog</Nav.Item> */}
                    {/* {user && */}
                    <Dropdown title="Properties">
                      <Dropdown.Item eventKey="rent">Houses For Rent</Dropdown.Item>
                      <Dropdown.Item eventKey="houses">Houses For Sale</Dropdown.Item>
                      <Dropdown.Item eventKey="cars">Cars</Dropdown.Item>
                      <Dropdown.Item eventKey="halls">Halls</Dropdown.Item>
                      <Dropdown.Item eventKey="hotels">Hotels</Dropdown.Item>
                      <Dropdown.Item eventKey="guest-houses">Guest Houses</Dropdown.Item>
                      <Dropdown.Item eventKey="lands">Lands</Dropdown.Item>
                    </Dropdown>
                    {/* } */}
                </Nav>
                <Nav onSelect={null}  style={{display: 'flex', alignItems: 'center', height: '100%', marginTop: 10}}>
                    <InputGroup style={{}} >
                        <Input placeholder="Search for any property" onChange={(value)=> setText(value)}/>
                        <InputGroup.Addon onClick={searching}><Icon icon="search" /></InputGroup.Addon>
                    </InputGroup>
                </Nav>
                <Nav onSelect={handleSelect} pullRight>
                {!user ? <div style={{display: 'flex', marginTop: 10}}>
                        <button onClick={() => navigate('/login')} style={{ backgroundColor: 'transparent', border: 0, cursor: 'pointer' }} className="navlink" >Sign In</button>
                        <button onClick={() => navigate('/signup')} className="actbtn btn-primary">Create Account</button>
                    </div> :
                    <Nav.Item eventKey="my-account" icon={<Icon icon="user" />}>Profile</Nav.Item>
                }
                </Nav>
                </Navbar.Body>
          </Container>
        </Navbar>
      </Styles>
    );
  };


const Styles = styled.div`
.navlink{
  padding: 4px 10px 4px 10px;
  text-decoration: none;
  font-size: 14px;
  color: #424242;
  align-items: center;
  display: flex;
  flex-direction: column;
  outline: none;

  &: hover{
    color: white;
  }
}

.anavlink{
  font-weight: bold;
  color: white;
}

.anavlink div{
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

#dropdown-basic {
  background-color: #F8F8FF;
  border: 0px;
  color: slategray;
}

.actbtn{
  border-radius: 3px;
  color: blue;
  border: 1px solid blue;
  background-color: white;
  margin-right: 15px;
  font-size: 13px;
  padding: 4px 10px;
  outline: none;
  cursor: pointer;
  height: 50px

  &: hover{
    color: white;
    background-color: blue;
  }
}
`

export default CustomNav;