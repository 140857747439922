import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Link, useNavigate  } from "react-router-dom";
import {setInstorage} from './../utils/Storage'
import { Alert } from 'rsuite';
import './css/signup.css'
import axios from '../utils/axios';
import { AuthContext } from './../App';

export default function Login() {

  const navigate = useNavigate();
  const {signIn} = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const login = async(e)=>{
    e.preventDefault()
    
    if(!email || !password){
      return Alert.error("All fields are required");
    }

    let dat = {
      email: email,
      pass: password,
    }
    // alert(dat);
    await axios.post('/users/login.php', dat, {withCredentials: false}).then((res)=>{
      console.log(res.data);
      if(res.data.status===200){
        Alert.success('Welcome back');
        let user=res.data.user;
        user.userId=user.id;
        setInstorage('userToken', JSON.stringify(res.data.user));
        signIn();
        navigate('/');
      }else{
        Alert.error(res.data.message);
      }
     
    }).catch((err)=>{
      console.log(err);
      Alert.error("Error signing in ")
    })
  }

  return (
    <Container className="page-wrapper bg-gra-02 p-t-130 p-b-100 font-poppins" fluid >
      <Row className="w-100 d-flex justify-content-center" >
        <Col xs={12} sm={6} md={4} className="rowform">
        <Form className="p-4">

        <Row className="mt-0 mb-3">
          <h5 style={{color: '#028499', textAlign: 'center'}}>Connection</h5>
        </Row>

        <Col className="mb-3">
          <Form.Group as={Col} controlId="formGridUsername" className="mb-3">
            <Form.Label style={{color: '#2E365F'}}>Email</Form.Label>
            <Form.Control type="email" placeholder="Email"  value={email} onChange={(e)=> setEmail(e.target.value)}/>
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label style={{color: '#2E365F'}}>Password</Form.Label>
            <Form.Control type="password" placeholder="Password"  value={password} onChange={(e)=> setPassword(e.target.value)}/>
          </Form.Group>
        </Col>

        <Col>
        <Form.Group as={Col} className="mb-4 mt-4" id="formGridCheckbox">
          <h6 style={{color: '#028499'}}><Link to="/forgot-password">Forget password ?</Link></h6>
        </Form.Group>
        </Col>

        <Row className="w-100 d-flex justify-content-center mb-4 mt-4">
          <Button onClick={login} variant="primary" type="submit" style={{backgroundColor: '#2E365F'}}  className="w-50">
            Login
          </Button>
        </Row>
        <nav>
          Don't have an account yet ?
        <Link to="/signup"> Regiser here</Link>
      </nav>
      </Form>
      </Col>
    </Row>
  </Container>
  )
}
