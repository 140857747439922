import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Alert } from 'rsuite';
import axios from '../utils/axios';

import loading from './../assets/loading.gif';
import './css/signup.css'

export default function PasswordReset(){
  const navigate = useNavigate();
  const params = useParams();
  const { hash } = params;
  const [auth, setAuth] = useState(0);
  const [password, setPassword] = useState("");
  const [pass, setPass] = useState("");
  const [userid, setUserid] = useState("");

  useEffect(() => {
    checkHash();
  }, [hash]);

  const checkHash = async() => {
    axios.post('/users/check_hash.php', { hash: hash }, {withCredentials: false})
    .then(res => {
      console.log(res.data);
      if(res.data.stat){
        setAuth(res.data.stat);
        setUserid(res.data.userid);
      }
    })
  }

  const resetPassword = async(e) => {
    e.preventDefault();
    if(!password || password.length < 8){
      return Alert.error("Invalid Password. Password must be at least 8 characters long");
    }

    if(password !== pass){
      return Alert.error("Password must match");
    }

    await axios.post("/users/update_password.php", { password: password, userid: userid })
    .then(res => {
      console.log(res.data)
      if(res.data.stat == 1){
        navigate("/login");
      }else{
        setAuth(-1);
      }
    })
  }

  return(
    <Styles>
      <Container className="page-wrapper bg-gra-02 p-t-130 p-b-100 font-poppins" fluid >
        <Row className="w-100 d-flex justify-content-center" >
          <Col xs={12} sm={6} md={4} className="rowform">
            {(auth == 0)?(
              <div style={{ width: "100%", display: 'flex', alignItems: 'center', justifyContent: 'center', height: 200 }}>
                <img src={loading} style={{ width: 40, height: 40 }} />
              </div>
            ):(auth == 1)?(
              <Form className="p-4">
                <Row className="mt-0 mb-3">
                  <h5 style={{color: '#028499', textAlign: 'center'}}>Reset Password</h5>
                  <p className='text-center'>Enter a new password for your account.</p>
                </Row>

                <Col className="mb-3">
                  <Form.Group as={Col} controlId="formGridUsername" className="mb-3">
                    <Form.Label style={{color: '#2E365F'}}>Password</Form.Label>
                    <Form.Control type="password" placeholder="Password"  value={password} onChange={(e)=> setPassword(e.target.value)}/>
                  </Form.Group>
                </Col>

                <Col className="mb-3">
                  <Form.Group as={Col} controlId="formGridUsername" className="mb-3">
                    <Form.Label style={{color: '#2E365F'}}>Confirm Password</Form.Label>
                    <Form.Control type="password" placeholder="********"  value={pass} onChange={(e)=> setPass(e.target.value)}/>
                  </Form.Group>
                </Col>

                <Row className="w-100 d-flex justify-content-center mb-4 mt-4">
                  <Button onClick={resetPassword} variant="primary" type="submit" style={{backgroundColor: '#2E365F'}}  className="w-50">
                    Reset Password
                  </Button>
                </Row>
              </Form>
            ):(
              <Form className="p-4">
                <Row className="mt-0 mb-3">
                  <h5 style={{color: '#991111', textAlign: 'center'}}>Expired Link!</h5>
                  <p className='text-center'>This password reset link has expired.</p>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </Styles>
  )
}

const Styles = styled.div``;