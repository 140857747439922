import React,  { useState, useContext, useEffect }  from 'react'
import { Row, Col, Form, Button } from 'react-bootstrap';
import Sidebar from '../components/Sidebar';
import './css/transaction.css';
import Table from 'react-bootstrap/Table';

import {getFromStorage} from './../utils/Storage'
import axios from '../utils/axios';

import { Alert } from 'rsuite';
import moment from 'moment';

export default function Transaction() {

    const [username, setUsername] = useState("");

    const [accBal, setaccBal] = useState("");
    const [tdep, settdep] = useState("");
    const [twith, settwith] = useState("");
    const [ttrans, setttrans] = useState("");

    //deposits variable
    const [depAmount, setDepAmount] = useState(0);
    const [depNumber, setDepNumber] = useState("");
    const [depService, setDepService] = useState("MTN");

    //deposits variable
    const [transAmount, setTransAmount] = useState(0);
    const [transNumber, setTransNumber] = useState("");
    const [transReason, setTransReason] = useState("");

    //deposits variable
    const [withAmount, setWithAmount] = useState(0);
    const [withNumber, setWithNumber] = useState("");
    const [withService, setWithService] = useState("MTN");

    const [transactions, setTransactions] = useState([]);
   

    const user = getFromStorage('userToken');


    useEffect(()=>{
        // console.log('this is the user ',JSON.parse(user).userId);
        getUserBalance();
        return ()=>{
            
        }
    }, []);

    const getUserBalance = async()=>{
        await axios.post('/users/get_user.php', {userId: JSON.parse(user).userId}).then((res)=>{
            console.log(res.data);
            let userDat = res.data.user;
            if(res.data.status === 200){
                setaccBal(userDat.balance);
                settdep(userDat.total_deposits);
                setttrans(userDat.total_transfers);
                settwith(userDat.total_withdrawals);
                setUsername(userDat.username)
                setTransactions(res.data.transactions);
            }else{

            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    //deposit function
    const processDeposit = async(e)=>{
        e.preventDefault();
        if(!depAmount || depAmount<=0){
            return Alert.error("Invalid amount")
        }

        if(!depNumber){
            return Alert.error("Phone number required")
        }
        let dat = {
            userId: JSON.parse(user).userId,
            amount: depAmount,
            number: depNumber,
            service: depService
        }
        await axios.post('/users/deposit.php', dat).then((res)=>{
            console.log(res.data);
            if(res.data.status === 200){
               Alert.success("Amount deposited successfuly");
               getUserBalance();
            }else{
                Alert.error(res.data.error)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

     //transfer function
     const processTransfer = async(e)=>{
        e.preventDefault();
        if(!transAmount || transAmount<=0){
            return Alert.error("Invalid amount")
        }
         if(!transNumber || !transReason){
            return Alert.error("Phone and Reason required")
        }
        let dat = {
            userId: JSON.parse(user).userId,
            amount: transAmount,
            number: transNumber,
            receiver: transReason
        }
        await axios.post('/users/transfer.php', dat).then((res)=>{
            console.log(res.data);
            if(res.data.status === 200){
                Alert.success("Amount Transfered successfuly");
                getUserBalance();
             }else{
                 Alert.error(res.data.message)
             }
        }).catch((err)=>{
            console.log(err);
        })
    }

     //widthdraw function
     const processWithdraw = async(e)=>{
        e.preventDefault();
        if(!withAmount || withAmount<=0){
            return Alert.error("Invalid amount")
        }
        if(!withNumber){
            return Alert.error("Phone number required")
        }
        let dat = {
            userId: JSON.parse(user).userId,
            amount: withAmount,
            number: withNumber,
            service: withService
        }
        await axios.post('/users/withdraw.php', dat).then((res)=>{
            console.log(res.data);
            if(res.data.status === 200){
                Alert.success("Amount withdrawn successfuly");
                getUserBalance();
            }else{
                Alert.error(res.data.error)
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
   
    return (
        <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: '100vh' }}>
            <Sidebar select={3}/>
            <div style={{ width: '90%', overflowY: 'auto', position: 'relative', backgroundColor: 'rgb(246, 248, 254)' }} className="cont">
                <div className='container'>
                    <div className='container transactionHead'>
                        <h5>{username}'s Transactions</h5>
                        <p>Deposit into your account and perform other transactions
                            like transfer and withdrawals with just a few clicks
                        </p>
                    </div>
                    <div className='mt-4'>
                        <Row>
                            <div className='col-6 col-sm-3'>
                                <div className='dashCard container bal'>
                                    <h6>Account Balance</h6>
                                    <span>{accBal} FCFA</span>
                                </div>
                            </div>
                            <div className='col-6 col-sm-3'>
                                <div className='dashCard container dep'>
                                    <h6>Total Deposits</h6>
                                    <span>{tdep} FCFA</span>
                                </div>
                            </div>
                            <div className='col-6 col-sm-3'>
                                <div className='dashCard container with'>
                                    <h6>Total Withdrawals</h6>
                                    <span>{twith} FCFA</span>
                                </div>
                            </div>
                            <div className='col-6 col-sm-3'>
                                <div className='dashCard container trans'>
                                    <h6>Total Transactions</h6>
                                    <span>{ttrans} FCFA</span>
                                </div>
                            </div>
                        </Row>
                    </div>

                    <div className='mt-4 mb-4'>
                        <Row>
                            <div className='col-xs-6 col-sm-6 col-md-4'>
                                <div className='transactionForm container'>
                                    <h6>Deposit</h6>
                                    <Row>
                                        <Col className="mb-3">
                                        <Form.Group as={Col} controlId="formGridtwith" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Amount</Form.Label>
                                            <Form.Control className="iputfield" type="text" placeholder="Enter Amount"  value={depAmount} onChange={(e)=> setDepAmount(e.target.value)}/>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Phone number</Form.Label>
                                            <Form.Control className="iputfield" type="email" placeholder="Enter Account number"  value={depNumber} onChange={(e)=> setDepNumber(e.target.value)}/>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Service</Form.Label>
                                            <Form.Select value={depService} onChange={(e)=> setDepService(e.target.value)}>
                                                <option value="MTN">MTN</option>
                                                <option value="ORANGE">ORANGE</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Button variant="primary"  onClick={processDeposit}
                                        style={{backgroundColor: '#2E365F', margin: 'auto'}}  className="w-100 mb-4 mt-4">
                                            Process
                                        </Button>
                                       
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className='col-xs-6 col-sm-6 col-md-4'>
                                <div className='transactionForm container'>
                                    <h6>Transfer</h6>
                                    <Row>
                                        <Col className="mb-3">
                                        <Form.Group as={Col} controlId="formGridtwith" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Amount</Form.Label>
                                            <Form.Control className="iputfield" type="text" placeholder="Enter Amount"  value={transAmount} onChange={(e)=> setTransAmount(e.target.value)}/>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Email</Form.Label>
                                            <Form.Control className="iputfield" type="email" placeholder="User email address"  value={transNumber} onChange={(e)=> setTransNumber(e.target.value)}/>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Reason</Form.Label>
                                            <Form.Control className="iputfield" type="email" placeholder="Reason of transfer"  value={transReason} onChange={(e)=> setTransReason(e.target.value)}/>
                                        </Form.Group>

                                        <Button variant="primary" onClick={processTransfer}
                                        style={{backgroundColor: '#2E365F', margin: 'auto'}}  className="w-100 mb-4 mt-4">
                                            Process
                                        </Button>
                                       
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                            <div className='col-xs-6 col-sm-6 col-md-4'>
                                <div className='transactionForm container'>
                                    <h6>Withdraws</h6>
                                    <Row>
                                        <Col className="mb-3">
                                        <Form.Group as={Col} controlId="formGridtwith" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Amount</Form.Label>
                                            <Form.Control className="iputfield" type="text" placeholder="Enter Amount"  value={withAmount} onChange={(e)=> setWithAmount(e.target.value)} />
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Phone number</Form.Label>
                                            <Form.Control className="iputfield" type="email" placeholder="Enter Account number"  value={withNumber} onChange={(e)=> setWithNumber(e.target.value)}/>
                                        </Form.Group>

                                        <Form.Group as={Col} controlId="formGridEmail" className="mb-3">
                                            <Form.Label style={{color: '#2E365F'}}>Service</Form.Label>
                                            <Form.Select value={withService} onChange={(e)=> setWithService(e.target.value)}>
                                                <option value="MTN">MTN</option>
                                                <option value="ORANGE">ORANGE</option>
                                            </Form.Select>
                                        </Form.Group>

                                        <Button variant="primary"  onClick={processWithdraw}
                                        style={{backgroundColor: '#2E365F', margin: 'auto'}}  className="w-100 mb-4 mt-4">
                                            Process
                                        </Button>
                                       
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Row>
                    </div>
                    
                    <div className='transactionForm container mt-4 mb-4'>
                        <h6>Past transactions</h6>
                        <div>
                            <Table responsive>
                                <thead>
                                    <tr>
                                    <th>#</th>
                                    <th>Type</th>
                                    <th>Receiver</th>
                                    
                                    <th>AMOUNT</th>
                                    <th>DATE</th>
                                    <th>STATUS</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transactions.map((i, j)=>{
                                        return(
                                            <tr key={j}>
                                            <td>{j+1}</td>
                                            <td>{i.type}</td>
                                            <td>{i.receiver}</td>
                                           
                                            <td>{i.amount} XFA</td>
                                            <td>{moment(i.timestamp).format('MMM DD YYYY')}</td>
                                            <td>success</td>
                                            </tr>
                                        )
                                    })}
                                   
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
