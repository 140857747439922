import React,  { useState, useRef, useEffect }  from 'react'
import Sidebar from '../components/Sidebar'
import { CDBIcon } from "cdbreact";
import { Card } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';

import './css/profile.css';
import {getFromStorage} from './../utils/Storage'
import axios from '../utils/axios';
import { Alert } from 'rsuite';

import { Message } from 'rsuite';

export default function Profile() {

    const [phone, setPhone] = useState("");
    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [country, setCountry] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");


    const [verify, setVerify] = useState("");

    const [front, setFront] = useState("");
    const [selfi, setSelfi] = useState("");

    const [profilepic, setProfilepic] = useState("");
    const [coverpic, setCoverpic] = useState("");

    const user = getFromStorage('userToken');

    const inputRef = useRef(null);
    const inputRefCover = useRef(null);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    inputRef.current.click();
  };

  const handleFileChange = async(event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    console.log('fileObj is', fileObj);
    let dat = new FormData();
    dat.append('id', JSON.parse(user).userId);
    dat.append('pic', event.target.files[0])

    await axios.post('/users/profile_pic.php', dat).then((res)=>{
        console.log(res.data);
        if(res.data.status === 200){
          Alert.success("Profile picture updated");
          event.target.value = null;
          getUser();
        }else{
          Alert.error(res.data.message);
        }
    }).catch((err)=>{
        console.log(err);
    })
  };

  const handleClickCover = () => {
    // 👇️ open file input box on click of other element
    inputRefCover.current.click();
  };

  const handleFileChangeCover = async(event) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }
    console.log('fileObj is', fileObj);
    let dat = new FormData();
    dat.append('id', JSON.parse(user).userId);
    dat.append('pic', event.target.files[0])

    await axios.post('/users/cover_pic.php', dat).then((res)=>{
        console.log(res.data);
        if(res.data.status === 200){
          Alert.success("Cover picture updated");
          event.target.value = null;
          getUser();
        }else{
          Alert.error(res.data.message);
        }
    }).catch((err)=>{
        console.log(err);
    })
  };


    useEffect(()=>{
        
        getUser();
    }, []);

    const getUser = async()=>{
        console.log('this is the user ', JSON.parse(user).userId);
        await axios.post('/users/get_user.php', {userId: JSON.parse(user).userId}, {withCredentials: false}).then((res)=>{
            console.log(res.data);
            let userDat = res.data.user;
            if(res.data.status === 200){
                setPhone(userDat.phone);
                setName(userDat.fullname);
                setCountry(userDat.country);
                setEmail(userDat.email);
                setGender(userDat.gender);
                setUsername(userDat.username);
                setVerify(userDat.verification_status);
                setProfilepic(userDat.profile_picture)
                setCoverpic(userDat.cover_picture)
            }else{

            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    const userVerify = async(e)=>{
        e.preventDefault();
        
        let dat = new FormData();

        dat.append('id', JSON.parse(user).userId);
        dat.append('front', front);
        dat.append('back', selfi);

        await axios.post('/users/id_verify.php', dat).then((res)=>{
            console.log(res.data);
            if(res.data.status === 200){
                Alert.success("Account verified successfuly");
                 getUser();
            }else{
                Alert.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
            Alert.error("Unknown error")
        })
    }

    const updateInfos = async(e)=>{
        e.preventDefault();
        if(!phone || !country || !gender || !name){
            return Alert.error("All fields are required");
        }
        let dat={
            id: JSON.parse(user).userId,
            phone: phone,
            country: country,
            gender: gender,
            fullname: name
        }

        await axios.post('/users/edit_profile.php', dat).then((res)=>{
            console.log(res.data);
            if(res.data.status === 200){
              Alert.success("Profile infos updated")
              getUser();
            }else{
              Alert.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
        })
    }
   
    return (
        <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: '100vh' }}>
            <Sidebar select={1}/>
            <div style={{ width: '100%', overflowY: 'auto', display: 'flex', backgroundColor: 'rgb(246, 248, 254)',
               flexDirection: 'column' }} className="cont">
                {verify==="pending" && 
                <div className='pb-4'>
                <Message
                    showIcon
                    closable
                    type="warning"
                    // title="Warning"
                    description="Your identity is not yet verified."
                    />
                </div>
                }
                <h5 className='container' style={{color: '#2E365F'}}>Welcome {username},</h5>
                <div className='profileContain'>
                    
                    <div style={{ height: 200 }} className="cardhead">
                        <div style={{width: '100%',height: '100%'}}>
                            <img style={{ objectFit: 'cover' }}
                            className="coverimage" height={200} width="100%"
                            src={`https://blackprincehouses.com/backend/users${coverpic}`} alt="Cover image"
                            />
                            <CDBIcon icon="edit" className='editcover' onClick={()=> handleClickCover()}/>
                            <input
                                style={{display: 'none'}}
                                ref={inputRefCover}
                                type="file"
                                onChange={handleFileChangeCover}
                            />
                        </div>
                        <div className="profileimg">
                            <img style={{ objectFit: 'cover' }} src={`https://blackprincehouses.com/backend/users${profilepic}`} width="100px" height="100px"/>

                            <CDBIcon icon="edit" className='editprofile' onClick={()=> handleClick()}/>
                            <input
                                style={{display: 'none'}}
                                ref={inputRef}
                                type="file"
                                onChange={handleFileChange}
                            />
                        </div>
                        <div className='profilepageinfo'>
                            <h3>{name}</h3>
                            <h6>{email}</h6>
                            <h4>{country}</h4>
                            <h5>{phone}</h5>
                            <div style={{ padding: 20, }}>
                              <label>Referral Link</label>
                              <input type="text" style={{ width: '100%', padding: "5px 10px", border: '1px solid #dbdbdb', outline: 'none' }} value={"https://blackprincehouses.com/signup/"+username} />
                            </div>
                            <hr/>
                            <span className="mt-2 pt-4">Verification status: <br/>
                                <Button variant="danger" style={{backgroundColor: '#2E365F'}} className="w-50">
                                    {verify}
                                </Button></span>
                        </div>
                    </div>
                    <div className='profileInfos'>
                        <h5>General informations</h5>
                        <div className='container infosBox'>
                            <Row className="w-100 d-flex justify-content-center" >
                                <Col className="rowform w-100">
                                <Form className="p-4 w-100">

                                    <Col className="mb-3 w-100" as={Row}>
                                    <Form.Group xs={12} sm={6} md={4}  as={Col} controlId="formGridUsername" className="mb-3">
                                        <Form.Label style={{color: '#2E365F'}}>Username</Form.Label>
                                        <Form.Control className="iputfield" type="text" placeholder="Username"  value={username} />
                                    </Form.Group>

                                    <Form.Group xs={12} sm={6} md={4}  as={Col} controlId="formGridEmail">
                                        <Form.Label style={{color: '#2E365F'}}>Email</Form.Label>
                                        <Form.Control className="iputfield" type="email" placeholder="Enter email"  value={email} />
                                    </Form.Group>

                                    <Form.Group xs={12} sm={6} md={4}  as={Col} controlId="formGridUsername" className="mb-3">
                                        <Form.Label style={{color: '#2E365F'}}>Full name</Form.Label>
                                        <Form.Control className="iputfield" type="text" placeholder="Full name"  value={name} onChange={(e)=> setName(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group xs={12} sm={6} md={4}  as={Col} controlId="formGridEmail">
                                        <Form.Label style={{color: '#2E365F'}}>Phone</Form.Label>
                                        <Form.Control className="iputfield" type="phone" placeholder="Enter phone number"  value={phone} onChange={(e)=> setPhone(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group xs={12} sm={6} md={4}  as={Col} controlId="formGridUsername" className="mb-3">
                                        <Form.Label style={{color: '#2E365F'}}>Country</Form.Label>
                                        <Form.Control className="iputfield" type="text" placeholder="Enter Country"  value={country} onChange={(e)=> setCountry(e.target.value)}/>
                                    </Form.Group>

                                    <Form.Group xs={12} sm={6} md={4}  as={Col} controlId="formGridEmail">
                                        <Form.Label style={{color: '#2E365F'}}>Gender</Form.Label>
                                        <Form.Control className="iputfield" type="email" placeholder="Select Gender"  value={gender} />
                                    </Form.Group>
                                    </Col>
                                </Form>
                                </Col>
                            </Row>
                            <Row className="w-100 d-flex justify-content-center mb-4 mt-0">
                                <Button variant="primary" onClick={updateInfos} style={{backgroundColor: '#2E365F'}}  className="w-50">
                                    Save
                                </Button>
                            </Row>
                            {verify==="pending" && 
                            <div className='container'>
                                <h6>Identity verification</h6>
                                <Form className="p-4">
                                        <Row>
                                            <Col xs={8} sm={8} md={8} className="mb-3">
                                            <Form.Group controlId="formGridUsername" className="mb-3">
                                                <Form.Label style={{color: '#2E365F'}}>Upload ID Card</Form.Label>
                                                <Form.Control className="iputfield" type="file" placeholder="Id" onChange={(e)=> setFront(e.target.files[0])}/>
                                            </Form.Group>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} >
                                                <img height='100px' width="100%" src={require('./images/idcard.webp')}/>
                                            </Col>
                                        </Row>
                                    </Form>
                            </div>
                            }
                        </div>
                        {verify==="pending" && 
                        <div className='container infosBox pt-4'>
                            <h6>Self verification</h6>
                            <Row className="w-100 d-flex justify-content-center" >
                                <Col className="rowform mt-4">
                                    <p>
                                    Take a clear photo of your ID Card next to your face, with all information clearly visible.
                                    </p>
                                    <Form className="p-4">
                                        <Row>
                                            <Col xs={8} sm={8} md={8} className="mb-3">
                                            <Form.Group controlId="formGridUsername" className="mb-3">
                                                <Form.Label style={{color: '#2E365F'}}>Take selfi with ID Card</Form.Label>
                                                <Form.Control className="iputfield" type="file" placeholder="Selfi" onChange={(e)=> setSelfi(e.target.files[0])}/>
                                            </Form.Group>
                                            </Col>
                                            <Col xs={4} sm={4} md={4} >
                                                <img height='100px' src={require('./images/img_selfie_verifi.webp')}/>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Col>
                            </Row>
                            <Row className="w-100 d-flex justify-content-center mb-1 mt-0">
                                <Button variant="primary" onClick={userVerify} style={{backgroundColor: '#2E365F'}} className="w-50">
                                    Verify
                                </Button>
                            </Row>
                        </div>
                        }
                    </div>
                </div>
                
            </div>
        </div>
    )
}
