import React, { useEffect, useState } from 'react';
import {Modal, Row, Col, Carousel, Button} from 'react-bootstrap';
import { CDBIcon } from "cdbreact";

import img2 from './../assets/imgs/2.jpg'
import axios from '../utils/axios';

function ModalDetailsRent({showrent, setShowrent, detail}) {
    const [index, setIndex] = useState(0);

  const handleClose = () => setShowrent(false);
  const handleshowrent = () => setShowrent(true);
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [country, setCountry] = useState("");
  const [email, setEmail] = useState("");
  
  const uploadPage =()=>{
    
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  useEffect(()=>{
        
    getUser();
}, []);

const getUser = async()=>{
    await axios.post('/users/get_user.php', {userId: detail.user_id}, {withCredentials: false}).then((res)=>{
        let userDat = res.data.user;
        if(res.data.status === 200){
            setPhone(userDat.phone);
            setName(userDat.fullname);
            setCountry(userDat.country);
            setEmail(userDat.email);
        }else{

        }
    }).catch((err)=>{
        console.log(err);
    })
}

  return (
    <>
      {/* <span className="agent" onClick={handleshowrent}>View</span> */}

      <Modal
        show={showrent}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Property Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row style={{ margin: 0 }}>
                <Col xs={12} lg={6}>
                    {/* <Carousel slide={false} activeIndex={index} onSelect={handleSelect}>
                        {detail.pics && detail.pics.map((ik, j)=>{
                            return(
                                <Carousel.Item key={j}>
                                    <img
                                    className="d-block w-100 pimg"
                                    src={`http://blackprincehouses.com/backend/properties/${ik}`}
                                    alt="First slide"
                                    />
                                </Carousel.Item>
                            )
                        })}
                        
                    </Carousel> */}
                    <img src={`http://blackprincehouses.com/backend/properties/${detail.image}`} width='100%'className='detail-image' />
                </Col>
                <Col xs={12} lg={6}>
                    <span style={{color: 'blue'}}>How much can you afford?</span>
                    <div className='mb-4' style={{display: 'flex', alignItems: 'center', marginTop: 10}}>
                        <CDBIcon style={{color: '#2E365F'}} icon="square" />
                        <span>{detail.category}</span>
                    </div>
                    <div className='container'>
                        <h6>{detail.country}-{detail.city}-{detail.neighborhood} </h6>
                        <span className='property-type'>Type: {detail.title || 'NONE'}</span>
                        <hr/>

                        {detail.category==='Rent' && 
                        <div className='homeproperty'>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                                <span>{detail.num_bedrooms} Beds</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="car" />
                                <span>{detail.num_garages} Garrage</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                                <span>{detail.num_kitchens} kitchen</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="square" />
                                <span>{detail.num_parlors} Palour</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                                <span>{detail?.num_toilet || 1} Toilet</span>
                            </div>
                        </div>
                        }
                        {detail.category==='Guest House' && 
                        <div className='homeproperty'>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                                <span>{detail.num_bedrooms} Beds</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="car" />
                                <span>{detail.num_garages} Garrage</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                                <span>{detail.num_kitchens} kitchen</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="square" />
                                <span>{detail.num_parlors} Palour</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                                <span>{detail?.num_toilet || 1} Toilet</span>
                            </div>
                        </div>
                        }
                         {detail.category==='Hall' && 
                         <div className='homeproperty'>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                                <span>{detail.width} M long,</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bath" /> */}
                                <span>{detail.length} M large,</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                                <span>{detail.title} available space</span>
                            </div>
                        </div>
                        }
                         {detail.category==='Cars' && 
                         <div className='homeproperty'>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                                <span> Model: {detail.width}</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="calendar" /> */}
                                <span>Year: {detail.lenght}</span>
                            </div>
                            {/* <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                                <span>20000 available space</span>
                            </div> */}
                        </div>
                        }

                        {detail.category==='Land' && 
                          <div className='homeproperty'>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                                <span style={{fontStyle: 'italic', fontWeight: '200'}}>Width</span>
                                <span>{detail.width} M long</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                                <span style={{fontStyle: 'italic', fontWeight: '200'}}>Height</span>
                                <span>{detail.length} M large</span>
                            </div>
                        </div>
                        }

                         {detail.category==='Sales' && 
                          <div className='homeproperty'>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                              <span>{detail.num_bedrooms} Bed</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>{detail.num_garages} Bath</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>{detail.num_kitchens} kitchen</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="square" />
                              <span>{detail.num_parlors}  Palour</span>
                          </div>
                          {/* <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>2 Pool</span>
                          </div> */}
                      </div>
                        }

                        {detail.category==='Hotel' && 
                            <div className='homeproperty'>
                            <div className='property'>
                               <span>{detail.num_pools}</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                                <span>Pools</span>
                            </div>
                            <div className='property'>
                               <span>{detail.num_bars}</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                                <span>Bars</span>
                            </div>
                            <div className='property'>
                               <span>{detail.num_wifi}</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                                <span>Wifi</span>
                            </div>
                            <div className='property'>
                               <span>{detail.num_air_conditioners}</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                                <span>Air C.</span>
                            </div>
                            <div className='property'>
                               <span>{detail.num_garages}</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                                <span>Garage</span>
                            </div>
                            <div className='property'>
                               <span>{detail.num_restaurants}</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                                <span>Restaurant</span>
                            </div>
                        </div>
                        }

                        <hr/>
                        <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                            <span style={{fontWeight: '700'}}> Price: XAF {detail.price_per_month}</span>
                            <span className="">
                                <CDBIcon style={{color: 'green'}} icon="square" />
                                <span style={{fontSize: 18}}>Active</span>
                            </span>
                        </div>
                        <hr/>
                        <div>
                            <h6 style={{color: '#2E365F', fontWeight: '700'}} >Description</h6>
                            <p>
                            {detail.other}
                            </p>
                        </div>
                        <hr/>
                        <div>
                            <h6 style={{color: '#2E365F', fontWeight: '700'}} >About Agent</h6>
                            <div className=''>
                                <div className='property row'>
                                  <span style={{fontSize: 12, color: 'gray'}}>Full Name</span>
                                  <span style={{fontSize: 14, fontWeight: 600}}>{name}</span>
                                </div>
                                <div className='property row'>
                                  <span style={{fontSize: 12, color: 'gray'}}>State</span>
                                  <span style={{fontSize: 14, fontWeight: 600}}>{country}</span>
                                </div>
                                <div className='property row'>
                                    <span style={{fontSize: 12, color: 'gray'}}>Number</span>
                                    <span style={{fontSize: 14, fontWeight: 600}}>{phone}</span>
                                </div>
                                <div className='property row'>
                                    <span style={{fontSize: 12, color: 'gray'}}>Email</span>
                                    <span style={{fontSize: 14, fontWeight: 600}}>{email}</span>
                                </div>
                            </div>
                        </div>
                        <hr/>
                      
                    </div>
                </Col>
                
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button href={`tel: ${phone}`} variant="primary" onClick={uploadPage}>
              Call Agent
          </Button>
          <Button href={`mailto: ${email}`} variant="warning" onClick={uploadPage}>
              Email Agent
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalDetailsRent;