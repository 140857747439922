import React, { useState, useEffect } from 'react';
import {Modal, Row, Col, Form, Button} from 'react-bootstrap';
import { CDBIcon } from "cdbreact";

import '../pages/css/viewPricing.css';


import {getFromStorage} from './../utils/Storage'
import axios from './../utils/axios';
import { Alert } from 'rsuite';

function Comments({com, pro_id, getBack}) {
    
    const [text, setText] = useState("");
    const [showcomment, setShowcomment] = useState(false)
    const [comment, setComment] = useState([]);
    const user = getFromStorage('userToken');



    // useEffect(()=>{
        
    //     getComments();
    // }, []);

    const handleShow = ()=>{
        setShowcomment(true);
        getComments();
    }

    const addComment = async()=>{
        let dat={
            property_id: pro_id,
            text: text,
            user_id: JSON.parse(user).userId
        }

        await axios.post('/properties/comment.php', dat).then((res)=>{
            console.log(res.data);
            if(res.data.status === 200){
                getComments();
                getBack();
               Alert.success("Comment added successfully")
            }else{
                Alert.error(res.data.message);
            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    const getComments = async()=>{
        await axios.post('/properties/get_comments.php', {property_id: pro_id}).then((res)=>{
            console.log(res.data);
            if(res.data.status === 200){
               setComment(res.data.comments);
            }else{

            }
        }).catch((err)=>{
            console.log(err);
        })
    }



  return (
    <>
    <div onClick={()=> handleShow()}>
        <CDBIcon className="property-icon" icon="comment" />
        <span>{com}</span>
    </div>
      <Modal
        show={showcomment}
        onHide={()=> setShowcomment(false)}
        backdrop="static"
        keyboard={false}
        // size="lg"
        scrollable={true}
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Comments</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className=''>
            {comment.length < 1 && <>
                <span>No comment yet</span>
            </>}
            {comment.map((i, j)=>{
                return(
                    <div style={{display: 'flex', flexDirection: 'column'}}>
                        <span style={{fontSize: 14}}>{i.text}</span>
                        <span style={{color: 'gray'}}>{`user${i.user_id}`}</span>
                        <hr/>
                    </div>
                )
            })}
        </div>
        </Modal.Body>
        <Modal.Footer>
            <div style={{display: 'flex', justifyContent: 'center', width: '100%'}}>
            <Form.Control type="text" placeholder="Enter your comment here"  value={text} onChange={(e)=> setText(e.target.value)}/>
            <Button onClick={()=> addComment()}>send</Button>
            </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Comments;