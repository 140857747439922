import React, { useState } from 'react';
import { Container, Row, Col, Form, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from '../utils/axios';

export default function ForgotPassword(){
  const navigate = useNavigate();
  const [rend, setRend] = useState(0);
  const [email, setEmail] = useState('');

  const findAccount = async(e) => {
    e.preventDefault();
    await axios.post('/users/find_account.php', { email: email }, {withCredentials: false})
    .then((res) => {
      console.log(res.data)
      if(res.data.stat == 1){
        setRend(1);
      }else{
        setRend(-1);
      }
    })
  }

  return(
    <Styles>
      <Container className="page-wrapper bg-gra-02 p-t-130 p-b-100 font-poppins" fluid >
        <Row className="w-100 d-flex justify-content-center" >
          <Col xs={12} sm={6} md={4} className="rowform">
            {(rend == 0)?(
              <Form className="p-4">
                <Row className="mt-0 mb-3">
                  <h5 style={{color: '#028499', textAlign: 'center'}}>Find Account</h5>
                  <p className='text-center'>Enter the email address associated with your account to continue.</p>
                </Row>

                <Col className="mb-3">
                  <Form.Group as={Col} controlId="formGridUsername" className="mb-3">
                    <Form.Label style={{color: '#2E365F'}}>Email</Form.Label>
                    <Form.Control type="email" placeholder="Email"  value={email} onChange={(e)=> setEmail(e.target.value)}/>
                  </Form.Group>
                </Col>
                <Row className="w-100 d-flex justify-content-center mb-4 mt-4">
                  <Button onClick={findAccount} variant="primary" type="submit" style={{backgroundColor: '#2E365F'}}  className="w-50">
                    Find Account
                  </Button>
                </Row>
              </Form>
            ):(rend == 1)?(
              <Form className="p-4">
                <Row className="mt-0 mb-3">
                  <h5 style={{color: '#028499', textAlign: 'center'}}>Account Found</h5>
                  <p className='text-center'>We have sent a recovery email to your email address. Follow the link in it to reset your password.</p>
                </Row>
              </Form>
            ):(
              <Form className="p-4">
                <Row className="mt-0 mb-3">
                  <h5 style={{color: '#991111', textAlign: 'center'}}>Account Not Found</h5>
                  <p className='text-center'>We could not find any account with the email address you provided</p>
                  <div className="mt-4 ml-auto mr-auto d-flex justify-content-space-evenly">
                    <Button onClick={() => setRend(0)} variant='light' className='mr-2 ml-2 w-50'>Try Again</Button>
                    <Button onClick={() => navigate('/signup')}  className="w-50">Sign Up</Button>
                  </div>
                </Row>
              </Form>
            )}
          </Col>
        </Row>
      </Container>
    </Styles>
  )
}

const Styles = styled.div``;