import React, { useState, useEffect } from 'react';
import {Modal, Row, Col, Form, Button} from 'react-bootstrap';
import { CDBIcon } from "cdbreact";
import { Alert } from 'rsuite';
import '../pages/css/viewPricing.css';

import img2 from './../assets/imgs/2.jpg';
import ModalDetailsRent from './ModalDetailsRent';
import {getFromStorage} from './../utils/Storage'
import axios from './../utils/axios';

import moment from "moment";

function ViewPricing({detail}) {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  
  const arr = [1, 2, 3];

  const [showrent, setShowrent] = useState(false);
  const [bal, setBal] = useState(0);
  const [viewer, setViewer] = useState(null)
  const user = getFromStorage('userToken');

  const handleShow = () => {
    if(viewer){
      setShowrent(true)
    }else{
      setShow(true);
    }
    
  };

  const buyNow = async(plan, dur)=>{
    let dat = {
      amount: plan,
      userID: JSON.parse(user).userId,
      reason: 'view',
      duration: dur
    }
    if(bal >= plan){
      await axios.post('/users/action.php', dat).then((res)=>{
        console.log(res.data);
        if(res.data.status === 200){
          handleClose();
          Alert.success('Plan activated');
          setShowrent(true);    
          // getUser();
        }else{
          Alert.error(res.data.message);
        }
    }).catch((err)=>{
        console.log(err);
    })
    }else{
      Alert.warning('Balance account is insuficient! Please recharge your account and retry')
    }
    
  }



  const getUser = async()=>{
    // setShowrent(true)
    console.log(user)
    if(!user){
      return Alert.error("You need to connect before viewing properties");
    }
    await axios.post('/users/get_user.php', {userId: JSON.parse(user).userId}, {timeout: 10000}).then((res)=>{
        console.log(res.data);
        let userDat = res.data.user;
        // setShowrent(true)
        // return;
        if(res.data.status === 200){
           setBal(userDat.balance);
           setViewer(res.data.viewer);
           if(res.data.viewer){
            let tim = parseInt(res.data.view_duration)+5;
            var newDateObj = moment(res.data.view_time).add(tim, 'h').toDate();
            
            var now = moment().toDate();

            console.log(newDateObj);
            console.log("now ", now)

            if (now > newDateObj) {
              console.log("is good");
              axios.post('/users/stop_view.php', {userId: JSON.parse(user).userId}).then((rs)=>{
                console.log(rs.data);
                console.log("stop view");
                setShow(true);
              }).catch((err)=>{
                  console.log(err);
                  Alert.error("Error viewing property");
              })
            } else {
              console.log('its not good');
              setShowrent(true)
            }
            
          }else{
            setShow(true);
          }
        }else{
          Alert.error("Error viewing property");
        }
    }).catch((err)=>{
        console.log(err);
        Alert.error("Error viewing property");
    })

  }
    

  return (
    <>
      <span style={{ flex: 1, backgroundColor: 'blue', color: 'white', cursor: 'pointer' }} className="agent" onClick={()=> getUser()}>View</span>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Our pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className=''>
        <Row> 
            <div className='col-12 col-sm-6 col-lg-4 pt-4 pb-4'>
                <div className='dashCard pricingbase'>
                    {/* <img src={img2} width='100%' height={160} style={{position: 'relative', zIndex: 0}}/> */}
                    <div className='container pt-4 pb-4'>
                        <h4 style={{textAlign: 'center'}}>Basic</h4>
                        <p style={{textAlign: 'center', fontSize: 12}}>
                            By subscribing to this plan, you will be able to view properties detail for an hour
                        </p>
                        <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold', }}>XFA500 / hr</p>
                        <span onClick={()=>buyNow(500, 1)}  className="agente base" style={{textAlign: 'center'}}>BUY NOW</span>
                        <span style={{textAlign: 'center', fontSize: 14, fontStyle: 'italic'}}>NB: your timer start immediately after the buy</span>
                    </div>
                </div>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 pt-4 pb-4'>
                <div className='dashCard pricingadvance'>
                    {/* <img src={img2} width='100%' height={160} style={{position: 'relative', zIndex: 0}}/> */}
                    <div className='container pt-4 pb-4'>
                        <h4 style={{textAlign: 'center'}}>Advanced</h4>
                        <p style={{textAlign: 'center', fontSize: 12}}>
                        By subscribing to this plan, you will be able to view properties detail for  6 hours                        </p>
                        <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold', }}>XFA1500 / 6hrs</p>
                        <span onClick={()=>buyNow(1500, 6)} className="agente advance" style={{textAlign: 'center'}}>BUY NOW</span>
                        <span style={{textAlign: 'center', fontSize: 14, fontStyle: 'italic'}}>NB: your timer start immediately after the buy</span>
                    </div>
                </div>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 pt-4 pb-4'>
                <div className='dashCard pricingpremium'>
                    {/* <img src={img2} width='100%' height={160} style={{position: 'relative', zIndex: 0}}/> */}
                    <div className='container pt-4 pb-4'>
                        <h4 style={{textAlign: 'center'}}>Premium</h4>
                        <p style={{textAlign: 'center', fontSize: 12}}>
                        By subscribing to this plan, you will be able to view properties detail for  12 hours  
                        </p>
                        <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold', }}>XFA3000 / 24hrs</p>
                        <span onClick={()=>buyNow(3000, 24)} className="agente premium" style={{textAlign: 'center'}}>BUY NOW</span>
                        <span style={{textAlign: 'center', fontSize: 14, fontStyle: 'italic'}}>NB: your timer start immediately after the buy</span>
                    </div>
                </div>
            </div>
        </Row>
    </div>
        </Modal.Body>
        <Modal.Footer  style={{textAlign: 'left', width: '100%'}}>
          {bal ?
            <span>
            NB: The amount you select will be deducted from your account balance
            </span>
            :
            <span>
            PS: your account balance is not enough. 
            Please Recharge your account and come back
            </span>
          }
          
        </Modal.Footer>
      </Modal>

      <ModalDetailsRent detail={detail} showrent={showrent} setShowrent={setShowrent}/>
    </>
  );
}

export default ViewPricing;