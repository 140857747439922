import React, { useRef, useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import styled from 'styled-components';

import NavBar from './../components/Navbar';
import Footer from './../components/Footer';
import { getFromStorage } from "./../utils/Storage";
import axios from './../utils/axios';
import { CDBIcon } from "cdbreact";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faPhone, faLocationPin, faAt } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'rsuite';
import s1 from './../assets/imgs/rent.jpg';
import s2 from './../assets/imgs/house.jpg';
import s3 from './../assets/imgs/hall.jpg';
import s4 from './../assets/imgs/hotel.jpg';
import s5 from './../assets/imgs/car.jpg';
import s6 from './../assets/imgs/4.jpg';
import s7 from './../assets/imgs/guest.jpg';

// import { AuthContext } from './../App';

import ViewPricing from './../components/ViewPricing';
import CustomNavbar from '../components/CustomNav';
import Comments from '../components/Comments';
import userImg from './../assets/user.png';
import moment from 'moment';


export default function Home() {
  const navigate = useNavigate();
  const ref = useRef(null);
  const user = getFromStorage('userToken')
  const [index, setIndex] = useState(0);
  const [properties, setProperties] = useState([]);
  const [images, setImages] = useState([{}, {}]);


  const proper = [
    {
      img: s1,
      type: "Apartment for rent",
      location: "Bamenda, Cameroon",      
      category: 'Homes for rent',
      price: '20000'
    }, {
      img: s2,
      type: "Houses",
      location: "Buea, Cameroon",
      category: 'Houses for sale',
      price: '1 500 000'
    }, {
      img: s3,
      type: "Clean Studio",
      location: "Mile 8, Mankon",
      category: 'Halls',
      price: '15000'
    }, {
      img: s4,
      type: "Hotels",
      location: "Kribi",
      category: 'Hotels for lodge',
      price: '20000'
    }, {
      img: s5,
      type: "2021 Toyota",
      location: "Cameroon",
      category: 'Cars for sale',
      price: '3000000'
    }, {
      img: s6,
      type: "Land",
      location: "Bamenda, Cameroon",
      category: 'Lands',
      price: '30000'
    }, {
      img: s7,
      type: "Apartment",
      location: "Bamenda, Cameroon",
      category: 'Guest Houses for rent',
      price: '20000'
    },
  ]


  useEffect(()=>{
    // console.log('this is the user ',JSON.parse(user).userId);
    getCarrousel()
    getRents();
    return ()=>{
      
    }
  }, []);

  const getCarrousel = async() => {
    await axios.post('/properties/carrousel.php')
    .then((res) => {
      if(res.data.status == 1){
        setImages(res.data.images);
      }
    })
  }

  const getRents = async()=>{
    await axios.post('/properties/properties.php', {category: 'all', lim: 10000}).then((res)=>{
        console.log(res.data);
        
        if(res.data.status === 200){
          setProperties(res.data.properties)
        }else{

        }
    }).catch((err)=>{
        console.log(err);
    })

  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
    };

    const onLike = async(pro_id)=>{
      await axios.post('/properties/like.php', {property_id: pro_id,user_id: JSON.parse(user).userId}).then((res)=>{
        console.log(res.data);
        if(res.data.status === 200){
          Alert.success("Likes added")
            getRents();
        }else{
          Alert.error(res.data.message);
        }
      }).catch((err)=>{
        console.log(err);
      })
  }

  const putCategory = (category) => {
    if(category === "Rent" || category === "Hall"){
      return "FOR RENT"
    }else if(category === "Sales" || category === "Cars" || category === "Land"){
      return "FOR SALE"
    }else if(category === "Guest House" || category === "Hotel"){
      return "For LODGE"
    }else{
      return category
    }
  }

  return (
    <Styles>
      {/* <NavBar /> */}
      <CustomNavbar activeKey='home'/>
      <div className='topper'>
      {images.length > 0 && <Carousel pause={false} fade={true} controls={false}  style={{ width: '100%', height: '92vh', maxHeight: 550 }}>
        {images.map(i => {
          return(
            <Carousel.Item interval={3500} style={{ width: '100%', height: '92vh' }}>
              <div className="backimg" style={{ backgroundImage: "url(https://blackprincehouses.com/backend/properties/"+i.image+")"}}>
                <div className="introsec">
                  <div className="sect">
                    <h4 className="title">{putCategory(i.cat)}</h4>
                    <span style={{ fontSize: 20 }}>{i.country}</span>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          )
        })}
      </Carousel>}
      </div>
      
      
      <Row className="bodycontent" style={{ margin: 0, marginBottom: 60, width: '100%' }}>
        <h4 className="title" style={{ textAlign: 'center', marginBottom: 50, marginTop: 70 }}>Latest Properties</h4>
          {/* <Container> */}
          <Row style={{ margin: 0, padding: 0, width: '100%' }}>
          {properties.map((item) => {
            const i = item[0];
            const pics = item[1];
            const user = item[2];
            return(
              <div className='col-12 col-md-4 col-lg-4 mb-4 mopad'>
                  <div style={{ backgroundColor: '#ccc' }} className='dashCard'>
                    {JSON.stringify(i.pics)}
                  <Carousel slide={false} activeIndex={index} onSelect={handleSelect}>
                          {pics && pics.map((pic, j)=>{
                              return(
                                  <Carousel.Item key={j}>
                                      <img
                                      className="d-block pimg"
                                      src={`https://blackprincehouses.com/backend/properties/${pic}`}
                                      alt="First slide"
                                      />
                                  </Carousel.Item>
                              )
                          })}
                          
                      </Carousel>
                      {/* <img loading="lazy" src={i.img} className="pimg"  style={{position: 'relative', zIndex: 0}}/> */}
                      <div className='container mt-2'>
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <div className="oneline" style={{}}>
                                <h6>{(i.category === 'Rent')?"Apartment for rent":i.category}</h6>
                                <span className='property-type oneline'>{i.country}-{i.city}</span>
                              </div>
                              <span style={{fontWeight: '700'}}>XAF {i.price_per_month}</span>
                            </div>
                          </div>
                          
                          <div style={{ display: 'flex', marginTop: 5, marginBottom: -5 }}>
                            <button disabled className="btn btn-primary" style={{ marginRight: 5 }}>
                              {/* {putCategory(i.category)} */}
                              {i? putCategory(i.category) : "For Sale"}
                            </button>
                            <button disabled className="btn btn-success">{i?.availability==='Yes' ? 'Available' :  'Not available'}</button>
                          </div>

                          <hr/>
                          {i.category==='Rent' && 
                          <div>
                      <div className='homeproperty'>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                              <span>{i.num_bedrooms} Beds</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="car" />
                              <span>{i.num_garages} Garrage</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>{i.num_kitchens} kitchen</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="square" />
                              <span>{i.num_parlors} Palour</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>{i?.num_toilet || 1} Toilet</span>
                          </div>
                      </div>
                      </div>
                      }
                      {i.category==='Guest House' && 
                      <div className='homeproperty'>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                              <span>{i.num_bedrooms} Beds</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="car" />
                              <span>{i.num_garages} Garrage</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>{i.num_kitchens} kitchen</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="square" />
                              <span>{i.num_parlors} Palour</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>{i?.num_toilet || 1} Toilet</span>
                          </div>
                      </div>
                      }
                        {i.category==='Hall' && 
                        <div className='homeproperty'>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                              <span>{i.width} M long,</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bath" /> */}
                              <span>{i.length} M large,</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                              <span>{i.title} available space</span>
                          </div>
                      </div>
                      }
                        {i.category==='Cars' && 
                        <div className='homeproperty'>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                              <span> Model: {i.width}</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="calendar" /> */}
                              <span>Year: {i.lenght}</span>
                          </div>
                          {/* <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>20000 available space</span>
                          </div> */}
                      </div>
                      }

                      {i.category==='Land' && 
                        <div className='homeproperty'>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                              <span style={{fontStyle: 'italic', fontWeight: '200'}}>Width</span>
                              <span>{i.width} M long</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                              <span style={{fontStyle: 'italic', fontWeight: '200'}}>Height</span>
                              <span>{i.length} M large</span>
                          </div>
                      </div>
                      }

                        {i.category==='Sales' && 
                        <div className='homeproperty'>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                            <span>{i.num_bedrooms} Bed</span>
                        </div>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                            <span>{i.num_garages} Bath</span>
                        </div>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                            <span>{i.num_kitchens} kitchen</span>
                        </div>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="square" />
                            <span>{i.num_parlors}  Palour</span>
                        </div>
                        {/* <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                            <span>{i?.num_toilet || 1} Toilet</span>
                        </div> */}
                    </div>
                      }

                      {i.category==='Hotel' && 
                          <div className='homeproperty'>
                          <div className='property'>
                              <span>{i.num_pools}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                              <span>Pools</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_bars}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                              <span>Bars</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_wifi}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                              <span>Wifi</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_air_conditioners}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                              <span>Air C.</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_garages}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                              <span>Garage</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_restaurants}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                              <span>Restaurant</span>
                          </div>
                      </div>
                      }
                          <hr/>
                          <div style={{marginTop: -20, marginBottom: -20}}>
                            Uploaded on: {moment(i.timestamp).format('MMMM Do YYYY, h:mm:ss a')}
                            {/* {moment.utc(i.timestamp).local().startOf('seconds').fromNow()} */}
                          </div>
                          <hr/>
                          <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                              <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80px'}}>
                                  <div  onClick={()=> onLike(i.id)}>
                                      <CDBIcon className="property-icon" icon="heart" />
                                      <span>{i.likes || 0}</span>
                                  </div>
                                  {/* <div>
                                      <CDBIcon className="property-icon" icon="comment" />
                                      <span>2</span>
                                  </div> */}
                                  {/* <Comments com={i.comments || 0} pro_id={i.id} getBack={getRents}/> */}
                              </div>
                              <ViewPricing detail={i}/>
                              
                              <img onClick={() => navigate(`/account/${user.id}`)} style={user?.profile_picture ? { width: 40, height: 40, objectFit: 'cover', margin: 5, borderRadius: 7, cursor: 'pointer' } 
                              : {width: 60, height: 60}} src={user?.profile_picture ? `https://blackprincehouses.com/backend/users${user?.profile_picture}` : userImg} />
                            
                          </div>
                      
                      </div>
                  </div>
              </div>
          )})}
            </Row>
          {/* </Container> */}
        </Row>

        <Row style={{ margin: 0, height: '100%', backgroundColor: 'white', color: 'black' }}>
          <Col style={{ padding: 0 }} md="7">
            <img style={{ width: '100%', objectFit: 'cover', height: 370, backdropFilter: 'blur' }} src={require('./../assets/app.png')} />
          </Col>
          <Col md="5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 30 }}>
            <h4>Download Our App</h4>
            <span>Get updates on properties in real-time from anywhere with our mobile application.</span>
            <div className="btnarea" style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              <img style={{ width: 170, marginRight: 15 }} src={require('./../assets/android.png')} />
              <img style={{ width: 170 }} src={require('./../assets/apple.png')} />
            </div>
          </Col>
        </Row>

        <Row style={{ margin: 0, marginTop: 70, marginBottom: 100 }}>
          <Col style={{ height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 50px' }} md="6">
            <div style={{ display: 'flex', marginBottom: 25, alignItems: 'center' }}>
              <div className="round">
                <FontAwesomeIcon icon={faLocationPin} />
              </div>
              <div>
                <h5>Address</h5>
                <span className="det">Bamenda, Northwest, Cameroon</span>
              </div>
            </div>
            <div style={{ display: 'flex', marginBottom: 25, alignItems: 'center' }}>
              <div className="round">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div>
                <h5>Phone</h5>
                <span className="det">(237)679-887-746 | (237)650-695-730  </span>
              </div>
            </div>
            <div style={{ display: 'flex', marginBottom: 25, alignItems: 'center' }}>
              <div className="round">
                <FontAwesomeIcon icon={faAt} />
              </div>
              <div>
                <h5>Support</h5>
                <span className="det">blackprinceltd@gmail.com</span>
              </div>
            </div>
          </Col>
          <Col md="6" style={{ height: 400, backgroundColor: 'white' }}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={require('./../assets/imgs/bamenda.png')} />
          </Col>
        </Row>
        <Footer />
    </Styles>
  )
}


const Styles = styled.div`
  background-color: #028599;
  color: black;
  width: 100vw;
  overflow-x: hidden;
  h5{
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
  .det{
    margin: 0px;
    padding: 0px;
  }
  .backimg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .introsec{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;
    background-color: rgba(0,0,0,0.3);
  }
  .sect{
    width: 400px;
    height: 150px;
    background-color: rgba(360,360,360,0.5);
    border-radius: 7px;
    padding: 15px 20px;
    color: black;
  }
  .title{
    font-size: 35px;
    line-height: 55px;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .props{
    // display: flex;
  }
  .datarow{
    flex: 1;
    height: 370px;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .datarow::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  .aproperty{
    width: 300px;
    height: 350px;
    background-color: #dbdbdb;
    margin: 10px 3%;
    display: inline-flex;
    border-radius: 5px;
    padding: 0px;
  }
  .pimg{
    width: 100%;
    height: 250px;
    border-radius: 5px;
  }
  .mbtn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &: hover{
      box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.4);
      opacity: 0.8;
    }
  }
  .round{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    color: white;
    background-color: #067aa9;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .bodycontent{
    
  }

  @media screen and (max-width: 770px){
    .sect{
      width: 90%;
    }
    .mopad{
      padding: 10px;
    }
    .btnarea{
      margin-bottom: 20px;
    }
    .bodycontent{
      margin-top: -100px;
    }
    .topper{
      margin-bottom: 50px;
    }
  }

`;