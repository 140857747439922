import React, {useEffect,useState} from 'react'
import Stepper from 'react-stepper-horizontal/lib/Stepper'
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Sidebar from '../components/Sidebar'
import NavBar from './../components/Navbar';
import Footer from './../components/Footer';

import { useNavigate, useLocation  } from 'react-router-dom';
import { Alert } from 'rsuite';

import {getFromStorage} from './../utils/Storage'
import axios from '../utils/axios';

export default function UploadHotels() {
  const { state } = useLocation();
  const navigate = useNavigate();
  const user = getFromStorage('userToken');
  const [steps, setSteps] = useState([
    {
        title: 'Type/Location',
        onClick: (e) => {
          e.preventDefault()
          console.log('onClick', 1);
          setCurrentstep(0);
        }
      }, {
        title: 'Details',
        onClick: (e) => {
          e.preventDefault()
          console.log('onClick', 2)
          setCurrentstep(1);
        }
      }, {
        title: 'Images',
        onClick: (e) => {
          e.preventDefault()
          console.log('onClick', 3);
          setCurrentstep(2);
        }
      }
    ])
    const [currentstep, setCurrentstep] = useState(0)

    const [country, setCountry] = useState('');
    const [city, setCity] = useState('');
    const [neighborhood, setNeighborhood] = useState('');
    const [type, setType] = useState('Simple room');
    const [price, setPrice] = useState('');
    const [availability, setAvailability] = useState('');
   
    const [num_garages, setGarages] = useState('');
    const [air_cond, setAir_cond] = useState('');
    const [num_pool, setNpools] = useState('');
    const [num_rest, setNRestau] = useState('');
    const [num_bars, setNbars] = useState('');
    const [num_wifi, setNwifi] = useState('')
   
    const [other, setOther] = useState('');
    const [file, setFile] = useState([]);

    useEffect(()=>{
      console.log(state);
      if(!state){
        Alert.warning("Select a plan before uploading");
        navigate('/dashboard')
      }
    }, [])

    const onClickNext = ()=>{
      if(currentstep < 2){
        setCurrentstep(value => value+1);
      }
    }

    const onClickBack = ()=>{
      if(currentstep > 0){
        setCurrentstep(value => value-1);
      }
    }

    const onClickSubmit = async()=> {
      if(!country || !city || !neighborhood || !type || !price
        || !availability || !num_garages || !air_cond
         || !num_wifi || !num_pool || !num_rest || !num_bars
         || !other ){
          return Alert.error("ALl fields are required");
        }

        if(file.length<2){
          return Alert.error("Upload atleast 2 images for the property")
        }
      let formData = new FormData();
      formData.append('category', 'Hotel');
      formData.append('user_id', JSON.parse(user).userId);
      formData.append('country', country);
      formData.append('city', city);
      formData.append('neighborhood', neighborhood);
      formData.append('title', type);
      formData.append('price_per_month', price);
      formData.append('availability', availability);
      formData.append('num_pools', num_pool);
      formData.append('num_bars', num_bars);
      formData.append('num_air_conditioners', air_cond);
      formData.append('num_restaurants', num_rest);
      formData.append('num_garages', num_garages);
      formData.append('num_wifi', num_wifi);

      formData.append('other', other);
      formData.append('duration', '6 months');
      formData.append('region', city);
      
      file.forEach((item, i) => {
        file.forEach((item, i) => {
          formData.append('image[]', item);
        })
      })

      await axios.post('/properties/new_property.php', formData, {headers: {
        "Content-Type": "multipart/form-data",
      }}).then((res)=>{
        console.log(res.data);
        if(res.data.status === 200){
          console.log('submitted');
          Alert.success("Property uploaded successfully");
          navigate('/dashboard');
        }else{
          Alert.error(res.data.message);
        }
      }).catch((err)=>{
          console.log(err);
      })
    }

    const buttonStyle = { background: '#E0E0E0', width: 150, padding: 12, textAlign: 'center', margin: '0 auto', marginTop: 32, borderRadius: 10 };



    return (
        <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: '100vh'  }}>
            {/* <NavBar /> */}
            <Sidebar select={2}/>
            <div style={{ width: '100%', overflowY: 'auto', position: 'relative', backgroundColor: 'rgb(246, 248, 254)',
               flexDirection: 'column' }} className="cont pt-3 pr-1">
                <h5 style={{textAlign: 'center'}}>Upload Hotels</h5>
            <Stepper 
                // onClick={()=> setCurrentstep()} 
                steps={ steps } activeStep={ currentstep }
                // disabledSteps={ [2] } 
            />
            
            <div className="mt-4"/>

            {currentstep === 0 && 
              <LocationProperty 
                country={country} setCountry={setCountry} 
                city={city} setCity={setCity}
                neighborhood={neighborhood} setNeighborhood={setNeighborhood}
                type={type} setType={setType}
                price={price} setPrice={setPrice}
                availability={availability} setAvailability={setAvailability}
              />
              
            }


            {currentstep === 1 && 
              <DetailProperty 
              num_garages={num_garages} setGarages={setGarages}
              air_cond={air_cond} setAir_cond={setAir_cond}
              num_pool={num_pool} setNpools={setNpools}
              num_rest={num_rest} setNRestau={setNRestau}
              num_bars={num_bars} setNbars={setNbars}
              num_wifi={num_wifi} setNwifi={setNwifi}
              other={other} setOther={setOther}
            
              />}


            {currentstep === 2 && <ImagesProperty file={file} setFile={setFile}/>}

            <div className='d-flex'>
              <div style={ buttonStyle } onClick={ onClickBack }>Back</div>

              {currentstep === 2 ? 
                <div style={ {...buttonStyle, backgroundColor: '#2E365F', color: 'white'} } onClick={ onClickSubmit }>Submit</div>
              :
              <div style={ {...buttonStyle, backgroundColor: '#2E365F', color: 'white'} } onClick={ onClickNext }>Next</div>
              }
            </div>
            </div>
        </div>
    )
}


function LocationProperty(props){

  return (
    <Row className="w-100 d-flex justify-content-center" >
    <Col sm={12} md={6} className="rowform">
    <Form className="p-4">

    <Row className="mt-1 mb-4">
      <h5 style={{color: '#028499', textAlign: 'left'}}>Please fill out all the fields below</h5>
    </Row>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridUsername">
        <Form.Label style={{color: '#2E365F'}}>Country</Form.Label>
        <Form.Control type="text" placeholder="Country" value={props.country} onChange={(e)=> props.setCountry(e.target.value)}/>
      </Form.Group>

      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label style={{color: '#2E365F'}}>City</Form.Label>
        <Form.Control type="text" placeholder="City" value={props.city} onChange={(e)=> props.setCity(e.target.value)}/>
      </Form.Group>
    </Row>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridPassword">
        <Form.Label style={{color: '#2E365F'}}>Neighbourhood</Form.Label>
        <Form.Control type="text" placeholder="Neighbourhood"  value={props.neighborhood} onChange={(e)=> props.setNeighborhood(e.target.value)}/>
       
      </Form.Group>

      <Form.Group as={Col} controlId="formGridPassword">
        <Form.Label style={{color: '#2E365F'}}>Property Type</Form.Label>
        <Form.Select aria-label="Select type of property" value={props.type} onChange={(e)=> props.setType(e.target.value)}>
          <option value="Simple room">Simple room</option>
          <option value="Modern room">Modern room</option>
          <option value="Classic room">Classic room</option>
          <option value="Suits">Suits</option>
        </Form.Select>
      </Form.Group>
    </Row>

    <Row className="mb-5">
      <Form.Group as={Col} controlId="formGridPhone">
        <Form.Label style={{color: '#2E365F'}}>Standard Price / day</Form.Label>
        <Form.Control type="phone" placeholder="Enter price" value={props.price} onChange={(e)=> props.setPrice(e.target.value)}/>
      </Form.Group>

      <Form.Group as={Col} controlId="formGridCountry">
        <Form.Label style={{color: '#2E365F'}}>Availability</Form.Label>
        <div className="">
          <Form.Check
            inline
            label="Yes"
            name="availability"
            type='radio'
            id='yes'
            value="Yes"
            checked={props.availability==="Yes"}
            onChange={(e)=> props.setAvailability("Yes")}
          />
          <Form.Check
            inline
            label="No"
            name="availability"
            type='radio'
            id='no'
            value="No"
            checked={props.availability==="No"}
            onChange={(e)=> props.setAvailability("No")}
          />
        </div>
      </Form.Group>
    </Row>
    
  </Form>
  </Col>
</Row>
  )
}

function DetailProperty(props){

  return (
    <Row className="w-100 d-flex justify-content-center" >
    <Col sm={12} md={6} className="rowform">
    <Form className="p-4">

    <Row className="mt-1 mb-4">
      <h5 style={{color: '#028499', textAlign: 'left'}}>Please fill out the property details</h5>
    </Row>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridUsername">
        <Form.Label style={{color: '#2E365F'}}>Number of Garrage</Form.Label>
        <Form.Control type="phone" placeholder="Garrage" value={props.num_garages} onChange={(e)=> props.setGarages(e.target.value)}/>
      </Form.Group>

      <Form.Group as={Col} controlId="formGridEmail">
        <Form.Label style={{color: '#2E365F'}}>Number of Air C.</Form.Label>
        <Form.Control type="phone" placeholder="Air conditionner" value={props.air_cond} onChange={(e)=> props.setAir_cond(e.target.value)}/>
      </Form.Group>
    </Row>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridPassword">
        <Form.Label style={{color: '#2E365F'}}>Number of wifi</Form.Label>
        <Form.Control type="phone" placeholder="Wifi" value={props.num_wifi} onChange={(e)=> props.setNwifi(e.target.value)}/>
       
      </Form.Group>

      <Form.Group as={Col} controlId="formGridPhone">
        <Form.Label style={{color: '#2E365F'}}>Number of pool</Form.Label>
        <Form.Control type="phone" placeholder="Pool" value={props.num_pool} onChange={(e)=> props.setNpools(e.target.value)}/>
      </Form.Group>
    </Row>

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridPassword">
        <Form.Label style={{color: '#2E365F'}}>Number of restaurant</Form.Label>
        <Form.Control type="phone" placeholder="Restaurant" value={props.num_rest} onChange={(e)=> props.setNRestau(e.target.value)}/>
       
      </Form.Group>

      <Form.Group as={Col} controlId="formGridPhone">
        <Form.Label style={{color: '#2E365F'}}>Number of Bars</Form.Label>
        <Form.Control type="phone" placeholder="Bars" value={props.num_bars} onChange={(e)=> props.setNbars(e.target.value)}/>
      </Form.Group>
    </Row>
        

    <Row className="mb-3">
      <Form.Group as={Col} controlId="formGridPhone">
        <Form.Label style={{color: '#2E365F'}}>Other information</Form.Label>
        <Form.Control type="text" placeholder="Description" value={props.other} onChange={(e)=> props.setOther(e.target.value)}/>
      </Form.Group>
    </Row>
    
  </Form>
  </Col>
</Row>
  )
}

function ImagesProperty({file, setFile}){

  const fileObj = [];
  const  fileArray = [];


  const uploadMultipleFiles = (e) => {
    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
        fileArray.push(fileObj[0][i]);
        // fileArray.push(URL.createObjectURL(fileObj[0][i]));
    }
    console.log(fileObj);
    setFile(fileArray)
  }

  const uploadFiles = (e) => {
      e.preventDefault()
      console.log(file)
  }


  return (
    <div className="container">
      <h5 className="mt-4 mb-4">Upload property images</h5>
      <form className="mt-4 mb-4">
        <Row className="form-group multi-preview">
            {(file || []).map((url, j) => {
              return(
                <Col key={j} className="col-6 col-sm-3">
                  <img src={URL.createObjectURL(url)} alt="..." width="100%" height={200} style={{ objectFit: 'cover' }} />
                </Col>
              )
            })}
        </Row>

        <div className="form-group mt-4 mb-4">
            <input type="file" className="form-control" onChange={uploadMultipleFiles} multiple />
        </div>
        {/* <button type="button" className="btn btn-danger btn-block" onClick={uploadFiles}>Upload</button> */}
      </form >
    </div>
  )
}

function ReviewDetails(){

  return (
    <div>

    </div>
  )
}