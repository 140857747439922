import React, { useRef, useState, useEffect } from 'react'

import { Container, Row, Col, Carousel } from 'react-bootstrap';
import styled from 'styled-components';
import { CDBIcon } from "cdbreact";
import CustomNav from './../../components/CustomNav';
import Footer from './../../components/Footer';
import { Link, useNavigate } from 'react-router-dom';
import Comments from '../../components/Comments';
import moment from 'moment';
import {getFromStorage} from './../../utils/Storage'
import axios from './../../utils/axios';
import { Alert } from 'rsuite';
import userImg from './../../assets/user.png';
import ViewPricing from './../../components/ViewPricing';

const images = require('./../../assets/gifs/hall.gif');

export default function Halls() {
  const ref = useRef(null);
 

  const [properties, setProperties] = useState([]);
  const [index, setIndex] = useState(0);
  const user = getFromStorage('userToken');
  const navigate = useNavigate();

  useEffect(()=>{
    // console.log('this is the user ',JSON.parse(user).userId);
    getRents();
    return ()=>{

    }
}, []);

const getRents = async()=>{
    await axios.post('/properties/properties.php', {category: 'Hall', lim: 1000}).then((res)=>{
        console.log(res.data);
        if(res.data.status === 200){
           setProperties(res.data.properties)
        }else{
          console.log('not getting anything')
        }
    }).catch((err)=>{
        console.log(err);
    })
}

const handleSelect = (selectedIndex, e) => {
setIndex(selectedIndex);
};

const onLike = async(pro_id)=>{
await axios.post('/properties/like.php', {property_id: pro_id,user_id: JSON.parse(user).userId}).then((res)=>{
  console.log(res.data);
  if(res.data.status === 200){
    Alert.success("Likes added")
      getRents();
  }else{
    Alert.error(res.data.message);
  }
}).catch((err)=>{
  console.log(err);
})
}

  return (
    <Styles>
      <CustomNav activeKey='halls'/>
      <div style={{position: 'relative'}}>
      <div className="topper">
      <Carousel pause={false} fade={true} controls={false}  style={{ position: 'relative', zIndex: 1, width: '100%', height: '92vh', maxHeight: 550 }}>
                <Carousel.Item interval={3500} style={{ width: '100%', height: '92vh' }}>
                <div className="backimg" style={{ backgroundImage: "url("+images+")"}}>
                    
                </div>
                </Carousel.Item>
        </Carousel>
        <div className="introsec" style={{position: 'absolute', top: 0, zIndex:2}}>
            <div className="container">
                <div className="sect">
                    <h4 className="title">HALL RENTALS BEWARE OF FRAUDSTERS</h4>
                </div>
            </div>
        </div>
      </div>
    </div>
            
      
    <Row style={{ margin: 0, marginTop: 75 }}>
          <Container>
            <Row>
            {properties.map(i => (
                <div className='col-12 col-md-4 col-lg-4 mb-4 mopad'>
                    <div className='dashCard'>
                        <Carousel slide={false} activeIndex={index} onSelect={handleSelect}>
                            {i.pics.map((i, j)=>{
                                return(
                                    <Carousel.Item key={j}>
                                        <img
                                        className="d-block w-100 pimg"
                                        src={`https://blackprincehouses.com/backend/properties/${i}`}
                                        alt="First slide"
                                        />
                                    </Carousel.Item>
                                )
                            })}
                            
                        </Carousel>
                        {/* <img loading="lazy" src={i.img} className="pimg"  style={{position: 'relative', zIndex: 0}}/> */}
                        <div className='container mt-2'>
                        <div style={{ display: 'flex' }}>
                              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <div className="oneline" style={{}}>
                                  <h6>{i.category}</h6>
                                  <span className='property-type oneline'>{i.country}-{i.city}</span>
                                </div>
                                <span style={{fontWeight: '700'}}>XAF {i.price_per_month}</span>
                              </div>
                            </div>
                            
                            <div style={{ display: 'flex', marginTop: 5, marginBottom: -5 }}>
                              <button disabled className="btn btn-primary" style={{ marginRight: 5 }}>
                                {i?.title}
                              </button>
                              <button disabled className="btn btn-success">{i?.availability==='Yes' ? 'Available' :  'Not available'}</button>
                            </div>
                            
                            <hr/>
                            <div className='homeproperty'>
                                <div className='property'>
                                    {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                                    <span>{i.width} M long,</span>
                                </div>
                                <div className='property'>
                                    {/* <CDBIcon style={{color: '#2E365F'}} icon="bath" /> */}
                                    <span>{i.length} M large,</span>
                                </div>
                                <div className='property'>
                                    {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                                    <span>{i.title} available space</span>
                                </div>
                            </div>
                            <hr/>
                          <div style={{marginTop: -20, marginBottom: -20}}>
                            Uploaded on: {moment(i.timestamp).format('MMMM Do YYYY, h:mm:ss a')}
                            {/* {moment.utc(i.timestamp).local().startOf('seconds').fromNow()} */}
                          </div>
                            <hr/>
                            <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80px'}}>
                                    <div onClick={()=> onLike(i.id)}>
                                        <CDBIcon className="property-icon" icon="heart" />
                                        <span>{i.likes || 0}</span>
                                    </div>
                                    {/* <div>
                                        <CDBIcon className="property-icon" icon="comment" />
                                        <span>2</span>
                                    </div> */}
                                </div>
                                {/* <span className="agent">View</span> */}
                                <ViewPricing detail={i}/>
                                <img onClick={() => navigate(`/account/${i.user.id}`)}   style={i?.user?.profile_picture ? { width: 40, height: 40, objectFit: 'cover', margin: 5 } 
                                : {width: 60, height: 60}} src={i?.user?.profile_picture ? `https://blackprincehouses.com/backend/users${i?.user?.profile_picture}` : userImg} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            </Row>
          </Container>
        </Row>
        
        <Footer />
    </Styles>
  )
}


const Styles = styled.div`
  background-color: #028599;
  color: black;
  h5{
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
  .det{
    margin: 0px;
    padding: 0px;
  }
  .backimg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .introsec{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: start;
    align-items: flex-end;
    padding-bottom: 100px;
    background-color: rgba(0,0,0,0.3);
  }
  .sect{
    width: 400px;
    // height: 150px;
    background-color: rgba(360,360,360,0.5);
    border-radius: 7px;
    padding: 15px 20px;
    color: black;
  }
  .title{
    font-size: 28px;
    line-height: 55px;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .props{
    // display: flex;
  }
  .datarow{
    flex: 1;
    height: 370px;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .datarow::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  .aproperty{
    width: 300px;
    // height: 350px;
    background-color: #dbdbdb;
    margin: 10px 3%;
    // display: inline-flex;
    border-radius: 5px;
    padding: 0px;
  }
  .pimg{
    width: 100%;
    height: 180px;
    border-radius: 5px;
  }
  .mbtn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &: hover{
      box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.4);
      opacity: 0.8;
    }
  }
  .round{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    color: white;
    background-color: #067aa9;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

.dashCard{
    background-color: white;
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
    box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
    height: 100%;
    margin: 0;
}

.dashCard h6{
    font-weight: bold;
}

.homeproperty{
    display: flex;
    justify-content: space-between;
}

.property{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.property span{
    font-size: 10px;
}

.property-type{
    font-weight: 300;
    color: #000;
    font-size: 14px;
    font-style: italic;   
}

.property-icon{
    color: #028599;
}

.property-icon:hover{
    color: red
}

.agent{
    border: 1px #028599 solid;
    border-radius: 10px;
    height: 40px;
    display: flex;
    align-items: center;
    width: 100px;
    justify-content: center
}

.agent:hover{
    background-color: #028599;
    color: white;
    cursor: pointer;
}

  @media screen and (max-width: 770px){
    .sect{
      width: 90%;
    }
    .mopad{
      padding: 10px;
    }
    .btnarea{
      margin-bottom: 20px;
    }
    .bodycontent{
      margin-top: 100px;
    }
    .topper{
      margin-bottom: 270px;
    }
  }

`;