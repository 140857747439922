import React, {useContext} from 'react'
import { CDBSidebar, CDBSidebarContent, CDBSidebarFooter, CDBSidebarHeader, CDBSidebarMenu, CDBSidebarMenuItem } from 'cdbreact';
import styled from 'styled-components';
import { Link, NavLink, useNavigate } from 'react-router-dom';
import { deleteStorage } from '../utils/Storage';
import { Alert } from 'rsuite';
import './sidebar.css';

import { AuthContext } from './../App';

export default function Sidebar({select}) {

    const userrole = 'client';
    const navigate = useNavigate();
    const {signOut} = useContext(AuthContext);

    const logout = ()=>{
        Alert.error('Account logged out')
        deleteStorage("userToken");
        signOut();
        navigate("/");
    }

    return (
        <Styles style={{ display: 'flex', height: '100vh', overflow: 'scroll initial' }}>
        <div className="mainsidebar">
        <CDBSidebar textColor="#fff" backgroundColor="linear-gradient(90deg, #2E365F,100%)" className="sidebardash">
            
            <CDBSidebarHeader prefix={<i className="fa fa-bars fa-large slidebarbutton"></i>}>
                <Link to="/" style={{textDecoration: 'none', color: 'white'}}>Blackprince</Link> 
            </CDBSidebarHeader>

            <CDBSidebarContent className="sidebar-content">
            <CDBSidebarMenu>
                {/* <NavLink exact to='/' activeClassName="activeClicked">
                <CDBSidebarMenuItem icon="th-large">Home</CDBSidebarMenuItem>
                </NavLink> */}
                <NavLink exact to='/' activeClassName="activeClicked" style={userrole==="client" ? {display:'block'} : {display:'none'}}>
                <CDBSidebarMenuItem className={select===4  ? "activeSlide": ""} icon="home">Home</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to='/dashboard' activeClassName="activeClicked" style={userrole==="client" ? {display:'block'} : {display:'none'}}>
                <CDBSidebarMenuItem className={select===2  ? "activeSlide": ""} icon="chart-line">Properties</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/my-account"  activeClassName="activeClicked" style={userrole==="client" ? {display: 'block'} : {display:'none'}}>
                <CDBSidebarMenuItem className={select===1  ? "activeSlide": ""} icon="user">Profile</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to="/transactions" activeClassName="activeClicked" style={userrole==="client" ? {display: 'block'} : {display:'block'}}>
                <CDBSidebarMenuItem className={select===3  ? "activeSlide": ""} icon="credit-card">Transaction</CDBSidebarMenuItem>
                </NavLink>
                {/* <NavLink exact to="/admin/manage-orders"  activeClassName="activeClicked" style={userrole==="client" ? {display: 'none'} : {display:'block'}}>
                <CDBSidebarMenuItem className={select===5  ? "activeSlide": ""} icon="shopping-cart">Manage Orders</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to='/admin/manage-clients' activeClassName="activeClicked" style={userrole==="client" ? {display:'none'} : {display:'block'}}>
                <CDBSidebarMenuItem className={select===3  ? "activeSlide": ""} icon="users">Manage Clients</CDBSidebarMenuItem>
                </NavLink>
                <NavLink exact to='/admin/manage-blogs' activeClassName="activeClicked" style={userrole==="client" ? {display:'none'} : {display:'block'}}>
                <CDBSidebarMenuItem className={select===6  ? "activeSlide": ""} icon="blog">Manage Blog</CDBSidebarMenuItem>
                </NavLink> */}
            </CDBSidebarMenu>
            </CDBSidebarContent>

            <CDBSidebarFooter style={{ textAlign: 'center', borderTop: "1px solid #555" }}>
            <div onClick={() => logout()} style={{ padding: "20px 5px", cursor: 'pointer' }}>
                Log out
            </div>
            </CDBSidebarFooter>
        </CDBSidebar>
        </div>
        </Styles>

    )
    }


const Styles = styled.div`
  .mainsidebar{
    position: -webkit-sticky;
    position: sticky;
    top: 0vh;
    height: 100vh;
  }
`;