import React, { useState, useContext } from 'react'
import Button from 'react-bootstrap/Button';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';
import { Link, useNavigate, useParams } from "react-router-dom";
import { Alert } from 'rsuite';
import {setInstorage} from './../utils/Storage'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'

import axios from '../utils/axios';
import './css/signup.css'

import { AuthContext } from './../App';

export default function Signup() {

  const {signIn} = useContext(AuthContext);
  const params = useParams();
  
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [username, setUsername] = useState("");
  const [country, setCountry] = useState("");
  const [gender, setGender] = useState("");
  const [email, setEmail] = useState("");
  const [referer, setReferer] = useState(params.username);
  const [password, setPassword] = useState("");

  const register = async(e)=>{
    e.preventDefault()
   
    if(!phone || !name || !username || !country || !gender || !email || !password){
      return Alert.error("All fields are required");
    }

    let dat = {
      username: username,
      fname: name,
      country: country,
      gender: gender,
      email: email,
      referer: referer,
      password: password,
      phone: phone,
    }
    // alert(dat);
    await axios.post('/users/signup.php', dat, {withCredentials: false}).then((res)=>{
      console.log(res.data);
      
      if(res.data.status===200){
        Alert.success("Account created successfully")
        setInstorage('userToken', JSON.stringify(res.data));
        signIn();
        navigate('/');
      }else{
        Alert.error(res.data.message);
      }
    }).catch((err)=>{
      console.log(err);
      Alert.error("Error creating account. Try again ")
    })
   
  }

  return (
    <Container className="page-wrapper bg-gra-02" fluid style={{paddingTop: 100, paddingBottom: 100}}>
      <Row className="w-100 d-flex justify-content-center" >
        <Col sm={12} md={6} className="rowform">
        <Form className="p-4">

        <Row className="mt-1 mb-4">
          <h5 style={{color: '#028499', textAlign: 'left'}}>Registration form</h5>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridUsername">
            <Form.Label style={{color: '#2E365F'}}>Email</Form.Label>
            <Form.Control type="text" placeholder="Email Address"  value={email} onChange={(e)=> setEmail(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label style={{color: '#2E365F'}}>Username</Form.Label>
            <Form.Control type="email" placeholder="Username"  value={username} onChange={(e)=> setUsername(e.target.value)} />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label style={{color: '#2E365F'}}>Password</Form.Label>
            <Form.Control type="text" placeholder="Password"  value={password} onChange={(e)=> setPassword(e.target.value)} />
           
          </Form.Group>

          <Form.Group as={Col} controlId="formGridPassword">
            <Form.Label style={{color: '#2E365F'}}>Confirm password</Form.Label>
            <Form.Control type="password" placeholder="Confirm password"  value={password} onChange={(e)=> setPassword(e.target.value)} />
          </Form.Group>
        </Row>

        <Row className="mb-5">
          <Form.Group as={Col} controlId="formGridPhone">
            <Form.Label style={{color: '#2E365F'}}>Phone</Form.Label>
            {/* <Form.Control type="phone" placeholder="Enter phone number" /> */}
            <PhoneInput limitMaxLength="12" 
              style={{display: 'flex'}} className="form-control" defaultCountry="US" country="US" value={phone} 
              onChange={setPhone} placeholder="Phone number" />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridCountry">
            <Form.Label style={{color: '#2E365F'}}>Select your gender</Form.Label>
            <div className="">
              <Form.Check
                inline
                label="Male"
                name="gender"
                type='radio'
                id='male'
                value="Male"
                checked={gender==="Male"}
                onChange={(e)=> setGender("Male")}
              />
              <Form.Check
                inline
                label="Female"
                name="gender"
                type='radio'
                id='female'
                value="Female"
                onChange={(e)=> setGender("Female")}
                checked={gender==="Female"}
              />
            </div>
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridUsername">
            <Form.Label style={{color: '#2E365F'}}>Country</Form.Label>
            <Form.Control type="text" placeholder="Country"  value={country} onChange={(e)=> setCountry(e.target.value)} />
          </Form.Group>

          <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label style={{color: '#2E365F'}}>Referer (Optional)</Form.Label>
            <Form.Control type="text" placeholder="Referer" defaultValue={params?.username} value={referer} onChange={(e)=> setReferer(e.target.value)} />
          </Form.Group>
        </Row>

        <Row className="mb-3">
          <Form.Group as={Col} controlId="formGridUsername">
            <Form.Label style={{color: '#2E365F'}}>Full name</Form.Label>
            <Form.Control type="text" placeholder="Enter your name" value={name} onChange={(e)=> setName(e.target.value)} />
          </Form.Group>

          {/* <Form.Group as={Col} controlId="formGridEmail">
            <Form.Label style={{color: '#2E365F'}}>Referer (Optional)</Form.Label>
            <Form.Control type="text" placeholder="Referer" />
          </Form.Group> */}
        </Row>

        <Form.Group className="mb-3 mt-4" id="formGridCheckbox">
          <Form.Check type="checkbox" label="By signing up you agree to our terms and conditions" />
        </Form.Group>

        <Row className="w-100 d-flex justify-content-center mb-4 mt-4">
          <Button variant="primary" type="btn" style={{}} className="w-50" onClick={register}>
            Register
          </Button>
        </Row>

        <nav>
          Already have an account ?
          <Link to="/login"> Connect now</Link>
        </nav>
        
      </Form>
      </Col>
    </Row>
  </Container>
  )
}
