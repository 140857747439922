import React,  {useState, useEffect, useMemo, createContext}  from "react";
import { BrowserRouter, Routes, Route, Link  } from "react-router-dom";

import User from "./pages/user/Index";

import Footer from './components/Footer';
import Signup from "./pages/Signup";
import Login from "./pages/Login";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import Profile from "./pages/Profile";
import Home from "./pages/Home";
import Dashboard from "./pages/Dashboard";
import { getFromStorage } from "./utils/Storage";
import Transaction from "./pages/Transaction";
import UploadRent from "./pages/UploadRent";
import Rent from "./pages/properties/Rent";
import Hotels from "./pages/properties/Hotels";
import Guest from "./pages/properties/Guest";
import Lands from "./pages/properties/Lands";
import Cars from "./pages/properties/Cars";
import Sales from "./pages/properties/Sales";
import Halls from "./pages/properties/Halls";
import UploadLands from "./pages/UploadLands";
import UploadGuest from "./pages/UploadGuest";
import UploadSales from "./pages/UploadSales";
import UploadCars from "./pages/UploadCars";
import UploadHotels from "./pages/UploadHotel";
import UploadHalls from "./pages/UploadHalls";
import Account from "./pages/Account";
import './App.css';

import Test from "./pages/Test";

import axios from "./utils/axios";

import whatsapp from "./assets/giphy.gif";

import moment from "moment";
import About from "./pages/About";
import Search from "./pages/Search";
import NotHome from "./pages/NotHome";

export const AuthContext = createContext();

function App() {
  const [isSigning, setSigning] = useState(false);
  const user = getFromStorage('userToken');

  useEffect(()=>{
    getToken();
  },[])

 

  const getToken = async()=>{
    if(user == null){
      setSigning(false);
    }else{
      setSigning(true);
    }
  }

  const authContext = useMemo(
    ()=>({
      signIn: async () =>{
        // getToken();
        setSigning(true);
        console.log("i am log in");
      },
      signOut: async()=>{
        console.log("i am logged out");
        setSigning(false);
      },
      signUp: async () =>{
        console.log("account created");
      }
    })
  )
  
    return (
      <AuthContext.Provider value={authContext}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path='/about' element={<About />} />
              
              <Route path="/property/rent" element={<Rent />} />
              <Route path="/property/hotels" element={<Hotels />} />
              <Route path="/property/guest-houses" element={<Guest />} />
              <Route path="/property/lands" element={<Lands />} />
              <Route path="/property/cars" element={<Cars />} />
              <Route path="/property/houses" element={<Sales />} />
              <Route path="/property/halls" element={<Halls />} />
              <Route path="*" element={<Home />} />
            {isSigning ? <>
              <Route path="/my-account" element={<Profile />} />
              
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/transactions" element={<Transaction />} />
              

              <Route path='/upload-property/rent' element={<UploadRent />} />
              <Route path='/upload-property/land' element={<UploadLands />} />
              <Route path='/upload-property/guest-house' element={<UploadGuest />} />
              <Route path='/upload-property/home' element={<UploadSales />} />
              <Route path='/upload-property/cars' element={<UploadCars />} />
              <Route path='/upload-property/hotels' element={<UploadHotels />} />
              <Route path='/upload-property/halls' element={<UploadHalls />} />
              <Route path='/search/:id' element={<Search />} />
              <Route path="/account/:id" element={<Account />} />
            </> : <>
              <Route path="signup" element={<Signup />} />
              <Route path="signup/:username" element={<Signup />} />
              <Route path="/login" element={<Login />} />
              <Route path="/forgot-password" element={<ForgotPassword />} />
              <Route path="/password-reset/:hash" element={<PasswordReset />} />
              
              <Route path='/about' element={<About />} />
            </>
            }
            {/* <Route path="/test" element={<Test />} /> */}
          </Routes>
          {/* <Footer /> */}
          <a href="https://chat.whatsapp.com/CqLJDF8coK4BAzIzgjlsBG" className="bigbtn" ><img className="whatsapp" src={whatsapp} style={{ width: 100, height: 100 }} /></a>
        </BrowserRouter>
      </AuthContext.Provider>
    );
}


export default App;
