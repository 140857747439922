import React, { useRef, useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import styled from 'styled-components';

import NavBar from './../components/Navbar';
import Footer from './../components/Footer';
import { getFromStorage } from "./../utils/Storage";
import axios from './../utils/axios';
import { CDBIcon } from "cdbreact";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faChevronLeft, faPhone, faLocationPin, faAt } from '@fortawesome/free-solid-svg-icons';
import { Alert } from 'rsuite';

import s1 from './../assets/imgs/rent.jpg';
import s2 from './../assets/imgs/house.jpg';
import s3 from './../assets/imgs/hall.jpg';
import s4 from './../assets/imgs/hotel.jpg';
import s5 from './../assets/imgs/car.jpg';
import s6 from './../assets/imgs/4.jpg';
import s7 from './../assets/imgs/guest.jpg';

// import { AuthContext } from './../App';

import ViewPricing from './../components/ViewPricing';
import CustomNavbar from '../components/CustomNav';

export default function NotHome() {

  const properties = [
    {
      img: s1,
      type: "Apartment for rent",
      location: "Bamenda, Cameroon",      
      category: 'Homes for rent',
      price: '20000'
    }, {
      img: s2,
      type: "Houses",
      location: "Buea, Cameroon",
      category: 'Houses for sale',
      price: '1 500 000'
    }, {
      img: s3,
      type: "Clean Studio",
      location: "Mile 8, Mankon",
      category: 'Halls',
      price: '15000'
    }, {
      img: s4,
      type: "Hotels",
      location: "Kribi",
      category: 'Hotels for lodge',
      price: '20000'
    }, {
      img: s5,
      type: "2021 Toyota",
      location: "Cameroon",
      category: 'Cars for sale',
      price: '3000000'
    }, {
      img: s6,
      type: "Land",
      location: "Bamenda, Cameroon",
      category: 'Lands',
      price: '30000'
    }, {
      img: s7,
      type: "Apartment",
      location: "Bamenda, Cameroon",
      category: 'Guest Houses for rent',
      price: '20000'
    },
  ]


  return (
    <Styles>
      {/* <NavBar /> */}
      <CustomNavbar activeKey='home'/>
      <div className="topper">
      <Carousel pause={false} fade={true} controls={false}  style={{ width: '100%', height: '92vh', maxHeight: 550 }}>
        {properties.map(i => {
          return(
            <Carousel.Item interval={3500} style={{ width: '100%', height: '92vh' }}>
              <div className="backimg" style={{ backgroundImage: "url("+i.img+")"}}>
                <div className="introsec">
                  <div className="sect">
                    <h4 className="title">{i.type}</h4>
                    <span>{i.location}</span>
                  </div>
                </div>
              </div>
            </Carousel.Item>
          )
        })}
      </Carousel>
      </div>
      
      
      <Row className='bodycontent' style={{ margin: 0, marginTop: 75, marginBottom: 60 }}>
        <h4 className="title" style={{ textAlign: 'center', marginBottom: 50 }}>Latest Properties</h4>
          {/* <Container> */}
          <Row style={{ margin: 0, padding: 0 }}>
            {properties.map((i, j) => (
                <div key={j} className='col-12 col-md-4 col-lg-4 mb-4 mopad'>
                    <div style={{ backgroundColor: '#ccc' }} className='dashCard'>
                        <img loading="lazy" src={i.img} className="pimg"  style={{position: 'relative', zIndex: 0}}/>
                        <div className='container mt-2'>
                            <h6>{i.location}</h6>
                            <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <span className='property-type'>{i.category}</span>
                                <span style={{fontWeight: '700'}}>XAF {i.price}</span>
                            </div>
                            
                            <hr/>
                            {i.category==='Rent' && 
                        <div className='homeproperty'>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                                <span>6 Beds</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="car" />
                                <span>3 Garrage</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                                <span>3 kitchen</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="square" />
                                <span>2 Palour</span>
                            </div>
                        </div>
                        }
                        {i.category==='Guest House' && 
                        <div className='homeproperty'>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                                <span>4 Beds</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="car" />
                                <span>1 Garrage</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                                <span>2 kitchen</span>
                            </div>
                            <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="square" />
                                <span>1 Palour</span>
                            </div>
                        </div>
                        }
                         {i.category==='Halls' && 
                         <div className='homeproperty'>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                                <span>200 M long,</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bath" /> */}
                                <span>300 M large,</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                                <span>2000 available space</span>
                            </div>
                        </div>
                        }
                         {i.category==='Cars' && 
                         <div className='homeproperty'>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                                <span> Model: Toyota</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="calendar" /> */}
                                <span>Year: 2022</span>
                            </div>
                            {/* <div className='property'>
                                <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                                <span>20000 available space</span>
                            </div> */}
                        </div>
                        }

                        {i.category==='Lands' && 
                          <div className='homeproperty'>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                                <span style={{fontStyle: 'italic', fontWeight: '200'}}>Width</span>
                                <span>1000 M long</span>
                            </div>
                            <div className='property'>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                                <span style={{fontStyle: 'italic', fontWeight: '200'}}>Height</span>
                                <span>500 M large</span>
                            </div>
                        </div>
                        }

                         {i.category==='Houses' && 
                          <div className='homeproperty'>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                              <span>4 Bed</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>4 Bath</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>1 kitchen</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="square" />
                              <span>2  Palour</span>
                          </div>
                          {/* <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>2 Pool</span>
                          </div> */}
                      </div>
                        }

                        {i.category==='Hotels' && 
                            <div className='homeproperty'>
                            <div className='property'>
                               <span>2</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                                <span>Pools</span>
                            </div>
                            <div className='property'>
                               <span>4</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                                <span>Bars</span>
                            </div>
                            <div className='property'>
                               <span>2</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                                <span>Wifi</span>
                            </div>
                            <div className='property'>
                               <span>6</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                                <span>Air C.</span>
                            </div>
                            <div className='property'>
                               <span>4</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                                <span>Garage</span>
                            </div>
                            <div className='property'>
                               <span>2</span>
                                {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                                <span>Restaurant</span>
                            </div>
                        </div>
                        }
                            <hr/>
                            <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80px'}}>
                                    <div  onClick={()=> Alert.error("Please make sure you are logged in ")}>
                                        <CDBIcon className="property-icon" icon="heart" />
                                        <span>2</span>
                                    </div>
                                </div>
                                
                                <span style={{ cursor: 'pointer' }} onClick={()=> Alert.error("Please make sure you are logged in ")} className="agent">View</span>

                            </div>
                        
                        </div>
                    </div>
                </div>
            ))}
            </Row>
          {/* </Container> */}
        </Row>

        <Row style={{ margin: 0, height: '100%', backgroundColor: '#ccc', color: 'black' }}>
          <Col style={{ padding: 0 }} md="7">
            <img style={{ width: '100%', objectFit: 'cover', height: 370, backdropFilter: 'blur' }} src={require('./../assets/app.png')} />
          </Col>
          <Col md="5" style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', marginTop: 30 }}>
            <h4>Download Our App</h4>
            <span>Get updates on properties in real-time from anywhere with our mobile application.</span>
            <div className='btnarea' style={{ display: 'flex', justifyContent: 'center', marginTop: 10 }}>
              <img style={{ width: 170, marginRight: 15 }} src={require('./../assets/android.png')} />
              <img style={{ width: 170 }} src={require('./../assets/apple.png')} />
            </div>
          </Col>
        </Row>

        <Row style={{ margin: 0, marginTop: 70, marginBottom: 100 }}>
          <Col style={{ height: 400, display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '0px 50px' }} md="6">
            <div style={{ display: 'flex', marginBottom: 25, alignItems: 'center' }}>
              <div className="round">
                <FontAwesomeIcon icon={faLocationPin} />
              </div>
              <div>
                <h5>Address</h5>
                <span className="det">Bamenda, Northwest, Cameroon</span>
              </div>
            </div>
            <div style={{ display: 'flex', marginBottom: 25, alignItems: 'center' }}>
              <div className="round">
                <FontAwesomeIcon icon={faPhone} />
              </div>
              <div>
                <h5>Phone</h5>
                <span className="det">(237)679-887-746 | (237)650-695-730 </span>
              </div>
            </div>
            <div style={{ display: 'flex', marginBottom: 25, alignItems: 'center' }}>
              <div className="round">
                <FontAwesomeIcon icon={faAt} />
              </div>
              <div>
                <h5>Support</h5>
                <span className="det">blackprinceltd@gmail.com</span>
              </div>
            </div>
          </Col>
          <Col md="6" style={{ height: 400, backgroundColor: 'white' }}>
            <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={require('./../assets/imgs/bamenda.png')} />
          </Col>
        </Row>
        <Footer />
    </Styles>
  )
}


const Styles = styled.div`
  background-color: #028599;
  color: black;
  h5{
    font-size: 20px;
    margin: 0px;
    padding: 0px;
  }
  .det{
    margin: 0px;
    padding: 0px;
  }
  .backimg{
    width: 100%;
    height: 100%;
    position: absolute;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }
  .introsec{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    padding-bottom: 100px;
    background-color: rgba(0,0,0,0.3);
  }
  .sect{
    width: 400px;
    height: 150px;
    background-color: rgba(360,360,360,0.5);
    border-radius: 7px;
    padding: 15px 20px;
    color: black;
  }
  .title{
    font-size: 35px;
    line-height: 55px;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 700;
  }
  .props{
    // display: flex;
  }
  .datarow{
    flex: 1;
    height: 370px;
    display: flex;
    flex-direction: column;
    overflow-x: scroll;
    overflow-y: hidden;
    scroll-behavior: smooth;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  .datarow::-webkit-scrollbar{
    width: 0;
    height: 0;
  }
  .aproperty{
    width: 300px;
    height: 350px;
    background-color: #dbdbdb;
    margin: 10px 3%;
    display: inline-flex;
    border-radius: 5px;
    padding: 0px;
  }
  .pimg{
    width: 100%;
    height: 70%;
    border-radius: 5px;
  }
  .mbtn{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    &: hover{
      box-shadow: 1px 2px 5px 1px rgba(0,0,0,0.4);
      opacity: 0.8;
    }
  }
  .round{
    width: 55px;
    height: 55px;
    border-radius: 50%;
    color: white;
    background-color: #067aa9;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 770px){
    .sect{
      width: 90%;
    }
    .mopad{
      padding: 10px;
    }
    .btnarea{
      margin-bottom: 20px;
    }
    .bodycontent{
      margin-top: 100px;
    }
    .topper{
      margin-bottom: 270px;
    }
  }

`;