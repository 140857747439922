import React, { useState } from 'react';
import {Modal, Row, Col, Form, Button} from 'react-bootstrap';
import { CDBIcon } from "cdbreact";
import { useNavigate } from "react-router-dom";
import '../pages/css/viewPricing.css';

import img2 from './../assets/imgs/2.jpg';


function SelectUploadProperty({handleClosepricing, handleShowpricing, setShow, show, select, setSelect,
  showpricing, setShowpricing}) {
  
  const [selectedProperty, setSelectedProperty] = useState('');
  const proper = ['Homes for Rent', 'Land', 'Guest Houses', 'Homes for sale', 'Cars', 'Hotels', 'Halls']
  const navigate = useNavigate();

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  
  const uploadPage =(duration)=>{
    if(selectedProperty==='Homes for Rent'){
      navigate('/upload-property/rent', {state: {plan: duration}});
    }else if(selectedProperty==='Land'){
      navigate('/upload-property/land', {state: {plan: duration}});
    }else if(selectedProperty==='Guest Houses'){
      navigate('/upload-property/guest-house', {state: {plan: duration}});
    }else if(selectedProperty==='Homes for sale'){
      navigate('/upload-property/home', {state: {plan: duration}});
    }else if(selectedProperty==='Cars'){
      navigate('/upload-property/cars', {state: {plan: duration}});
    }else if(selectedProperty==='Hotels'){
      navigate('/upload-property/hotels', {state: {plan: duration}});
    }else if(selectedProperty==='Halls'){
      navigate('/upload-property/halls', {state: {plan: duration}});
    }
  }

  return (
    <>
      <span className="agente" onClick={handleShowpricing}>Upload Property</span>

      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Select one property to Upload</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Row>
              {proper.map((i, j)=>{
                return (
                  <span key={j} className="agentup" onClick={()=> setSelectedProperty(i)} style={i===selectedProperty ? {backgroundColor: '#028599', color: '#fff'}: {}}>{i}</span> 
                )
              })}
            </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={()=>uploadPage(select)}>
              Upload
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default SelectUploadProperty;