import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { CDBIcon } from "cdbreact";
import { useParams, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import axios from './../utils/axios';
import { Alert } from 'rsuite';

import ViewPricing from './../components/ViewPricing';
import CustomNav from './../components/CustomNav';
import userImg from './../assets/user.png';
import coverimg from './../assets/cover.webp';

export default function Account(){
  const navigate = useNavigate();
  const { id } = useParams();
  const [user, setUser] = useState({});
  const [index, setIndex] = useState(0);
  const [properties, setProperties] = useState([]);

  useEffect(()=>{
    if(id && parseInt(id)){
      getRents();
    }else{
      navigate('/')
    }
    return ()=>{ 
    }
  }, []);

  const getRents = async()=>{
    await axios.post('/users/get_user.php', {userId: id })
    .then((res)=>{
      if(res.data.status === 200){
        setProperties(res.data.properties);
        setUser(res.data.user)
        console.log(res.data)
      }else{
      }})
    .catch((err)=>{
      console.log(err);
    })

  }

  const onLike = async(pro_id)=>{
    await axios.post('/properties/like.php', {property_id: pro_id,user_id: JSON.parse(user).userId}).then((res)=>{
      console.log(res.data);
      if(res.data.status === 200){
        Alert.success("Likes added")
          getRents();
      }else{
        Alert.error(res.data.message);
      }
    }).catch((err)=>{
      console.log(err);
    })
  }

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };


  return(
    <Styles>
      <CustomNav activeKey={''} />
      <Container className="main" style={{ marginTop: 50 }}>
        <img src={user.cover_picture !== "n/a"? `http://blackprincehouses.com/backend/users${user?.cover_picture}`: coverimg} className="cover" />
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
          <img src={user?.profile_picture !== "n/a" ? `http://blackprincehouses.com/backend/users${user?.profile_picture}` : userImg} className="pic" />
          <div style={{ marginLeft: 7, flex: 1 }}>
            <h6>{user.fullname}</h6>
            <small style={{ marginTop: -10 }}>@{user.username}</small>
          </div>
        </div>
        <div className="band">
          <h5 style={{ marginBottom: 15 }}>Properties</h5>

          
          <Row style={{ margin: 0, padding: 0, width: '100%' }}>
          {properties.map((item) => {
            const i = item;
            const {pics} = item;
            return(
              <div className='col-12 col-md-4 col-lg-4 mb-4 mopad'>
                  <div style={{ backgroundColor: '#ccc' }} className='dashCard'>
                  <Carousel slide={false} activeIndex={index} onSelect={handleSelect}>
                          {pics && pics.map((pic, j)=>{
                              return(
                                  <Carousel.Item key={j}>
                                      <img
                                      className="d-block pimg"
                                      src={`http://blackprincehouses.com/backend/properties/${pic}`}
                                      alt="First slide"
                                      />
                                  </Carousel.Item>
                              )
                          })}
                          
                      </Carousel>
                      {/* <img loading="lazy" src={i.img} className="pimg"  style={{position: 'relative', zIndex: 0}}/> */}
                      <div className='container mt-2'>
                          <div style={{ display: 'flex' }}>
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                              <div className="oneline" style={{}}>
                                <h6>{(i.category === 'Rent')?"Apartment for rent":i.category}</h6>
                                <span className='property-type oneline'>{i.country}-{i.city}</span>
                              </div>
                              <span style={{fontWeight: '700'}}>XAF {i.price_per_month}</span>
                            </div>
                          </div>
                          
                          <div style={{ display: 'flex', marginTop: 5, marginBottom: -5 }}>
                            <button disabled className="btn btn-primary" style={{ marginRight: 5 }}>
                              {/* {putCategory(i.category)} */}
                              {i?.title}
                            </button>
                            <button disabled className="btn btn-success">{i?.availability==='yes' ? 'Available' :  'Not available'}</button>
                          </div>

                          <hr/>
                          {i.category==='Rent' && 
                          <div>
                      <div className='homeproperty'>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                              <span>{i.num_bedrooms} Beds</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="car" />
                              <span>{i.num_garages} Garrage</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>{i.num_kitchens} kitchen</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="square" />
                              <span>{i.num_parlors} Palour</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>{i?.num_toilet || 1} Toilet</span>
                          </div>
                      </div>
                      </div>
                      }
                      {i.category==='Guest House' && 
                      <div className='homeproperty'>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                              <span>{i.num_bedrooms} Beds</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="car" />
                              <span>{i.num_garages} Garrage</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>{i.num_kitchens} kitchen</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="square" />
                              <span>{i.num_parlors} Palour</span>
                          </div>
                          <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                              <span>{i?.num_toilet || 1} Toilet</span>
                          </div>
                      </div>
                      }
                        {i.category==='Hall' && 
                        <div className='homeproperty'>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                              <span>{i.width} M long,</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bath" /> */}
                              <span>{i.length} M large,</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                              <span>{i.title} available space</span>
                          </div>
                      </div>
                      }
                        {i.category==='Cars' && 
                        <div className='homeproperty'>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                              <span> Model: {i.width}</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="calendar" /> */}
                              <span>Year: {i.lenght}</span>
                          </div>
                          {/* <div className='property'>
                              <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                              <span>20000 available space</span>
                          </div> */}
                      </div>
                      }

                      {i.category==='Land' && 
                        <div className='homeproperty'>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                              <span style={{fontStyle: 'italic', fontWeight: '200'}}>Width</span>
                              <span>{i.width} M long</span>
                          </div>
                          <div className='property'>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                              <span style={{fontStyle: 'italic', fontWeight: '200'}}>Height</span>
                              <span>{i.length} M large</span>
                          </div>
                      </div>
                      }

                        {i.category==='Sales' && 
                        <div className='homeproperty'>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="bed" />
                            <span>{i.num_bedrooms} Bed</span>
                        </div>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                            <span>{i.num_garages} Bath</span>
                        </div>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="utensils" />
                            <span>{i.num_kitchens} kitchen</span>
                        </div>
                        <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="square" />
                            <span>{i.num_parlors}  Palour</span>
                        </div>
                        {/* <div className='property'>
                            <CDBIcon style={{color: '#2E365F'}} icon="bath" />
                            <span>{i?.num_toilet || 1} Toilet</span>
                        </div> */}
                    </div>
                      }

                      {i.category==='Hotel' && 
                          <div className='homeproperty'>
                          <div className='property'>
                              <span>{i.num_pools}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="bed" /> */}
                              <span>Pools</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_bars}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="car" /> */}
                              <span>Bars</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_wifi}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                              <span>Wifi</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_air_conditioners}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                              <span>Air C.</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_garages}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="utensils" /> */}
                              <span>Garage</span>
                          </div>
                          <div className='property'>
                              <span>{i.num_restaurants}</span>
                              {/* <CDBIcon style={{color: '#2E365F'}} icon="square" /> */}
                              <span>Restaurant</span>
                          </div>
                      </div>
                      }
                          <hr/>
                          <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                              <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80px'}}>
                                  <div  onClick={()=> onLike(i.id)}>
                                      <CDBIcon className="property-icon" icon="heart" />
                                      <span>{i.likes || 0}</span>
                                  </div>
                                  {/* <div>
                                      <CDBIcon className="property-icon" icon="comment" />
                                      <span>2</span>
                                  </div> */}
                                  {/* <Comments com={i.comments || 0} pro_id={i.id} getBack={getRents}/> */}
                              </div>
                              <ViewPricing detail={i}/>
                              
                              <img onClick={() => navigate(`/account/${user.id}`)} style={user?.profile_picture ? { width: 40, height: 40, objectFit: 'cover', margin: 5, borderRadius: 7 } 
                              : {width: 60, height: 60}} src={user?.profile_picture ? `http://blackprincehouses.com/backend/users${user?.profile_picture}` : userImg} />
                            
                          </div>
                      
                      </div>
                  </div>
              </div>
          )})}
            </Row>



        </div>
      </Container>
    </Styles>
  )
}

const Styles = styled.div`
  background-color: #028599;
  color: black;
  .cover{
    width: 100%;
    height: 200px;
    background-color: #dbdbdb;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
  }
  .pic{
    width: 90px;
    height: 90px;
    background-color: #ebebeb;
    margin-left: 30px;
    border-radius: 50%;
    margin-top: -40px;
  }
  .band{
    margin-top: 30px;
  }

  @media screen and (max-width: 770px){
    .main{
      padding-top: 90px;
    }
  }

`;