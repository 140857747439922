import React, {useState, useEffect} from 'react'
import Sidebar from '../components/Sidebar'
import UploadPricing from '../components/UploadPricing'
import SelectUploadProperty from '../components/SelectUploadProperty'

import { Container, Row, Col, Carousel } from 'react-bootstrap';
import { CDBIcon } from "cdbreact";

import {getFromStorage} from './../utils/Storage'
import axios from '../utils/axios';
import { Alert } from 'rsuite';

import './css/dashboard.css'

import ViewPricing from '../components/ViewPricing';

import Comments from '../components/Comments';

const images = [require('./../assets/imgs/1.jpg'), require('./../assets/imgs/2.jpg'), require('./../assets/imgs/3.jpg')]

export default function Dashboard() {

    const [index, setIndex] = useState(0);

    const [show, setShow] = useState(false);

    const [select, setSelect] = useState(null);

    const user = getFromStorage('userToken');

    const [showpricing, setShowpricing] = useState(false);
    const handleClosepricing = () => setShowpricing(false);
    const handleShowpricing = () => setShowpricing(true);

    const [properties, setProperties] = useState([]);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };



    useEffect(()=>{
        // console.log('this is the user ',JSON.parse(user).userId);
        getUser();

        return ()=>{

        }
    }, []);

    const getUser = async()=>{
        await axios.post('/users/get_user.php', {userId: JSON.parse(user).userId}).then((res)=>{
            console.log(res.data);
            let userDat = res.data.user;
            if(res.data.status === 200){
               setProperties(res.data.properties)
            }else{

            }
        }).catch((err)=>{
            console.log(err);
        })
    }

    const onLike = async(pro_id)=>{
        await axios.post('/properties/like.php', {property_id: pro_id,user_id: JSON.parse(user).userId}).then((res)=>{
          console.log(res.data);
          if(res.data.status === 200){
            Alert.success("Likes added")
            getUser();
          }else{
            Alert.error(res.data.message);
          }
      }).catch((err)=>{
          console.log(err);
      })
      }

      const onDelete = async(pro_id)=>{
        await axios.post('/properties/delete_property.php', {property_id: pro_id}).then((res)=>{
          console.log(res.data);
          if(res.data.status === 200){
            Alert.success("Property deleted")
            getUser();
          }else{
            Alert.error(res.data.message);
          }
      }).catch((err)=>{
          console.log(err);
      })
      }

   
    return (
        <div style={{ display: 'flex', flexDirection: 'row', overflow: 'hidden', height: '100vh' }}>
            <Sidebar select={2}/>
            <div style={{ width: '100%', overflowY: 'auto', position: 'relative', backgroundColor: 'rgb(246, 248, 254)',
               flexDirection: 'column' }} className="cont pt-3 pr-1">
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                    <h5 className='container' style={{color: '#2E365F'}}>Manage propeties</h5>
                    <div style={{display: 'flex'}}>
                        <UploadPricing  handleClosepricing={handleClosepricing} handleShowpricing={handleShowpricing} show={show} setShow={setShow}
                         showpricing={showpricing} setShowpricing={setShowpricing} select={select} setSelect={setSelect}/>
                        <SelectUploadProperty handleClosepricing={handleClosepricing} handleShowpricing={handleShowpricing} show={show} setShow={setShow}
                         showpricing={showpricing} setShowpricing={setShowpricing} select={select} setSelect={setSelect}/>
                    </div>
                </div>
                <hr/>
                <Row style={{ margin: 0, marginTop: 25 }}>
                    {/* <h4 className="title" style={{ textAlign: 'center', marginBottom: 50 }}>Houses For Rent</h4> */}
                    <Container>
                        <Row>
                        {properties.map((i, k) => (
                            <div key={k} className='col-12 col-sm-6 col-md-4 col-lg-3 mb-4'>
                                <div className='dasCard'>
                                    <Carousel slide={false} activeIndex={index} onSelect={handleSelect}>
                                        {i.pics.map((i, j)=>{
                                            return(
                                                <Carousel.Item key={j}>
                                                    <img
                                                    className="d-block w-100 pimg"
                                                    src={`http://blackprincehouses.com/backend/properties/${i}`}
                                                    alt="First slide"
                                                    />
                                                </Carousel.Item>
                                            )
                                        })}
                                        
                                    </Carousel>
                                    {/* <img loading="lazy" src={i.img} className="pimg"  style={{position: 'relative', zIndex: 0}}/> */}
                                    <div className='container mt-2'>
                                        <h3>{i.category}</h3>
                                        <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <span className='property-type'>Price:</span>
                                            <span style={{fontWeight: '700'}}>XAF {i.price_per_month}</span>
                                        </div>
                                        <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <span className='property-type'>Plan duration:</span>
                                            <span style={{fontWeight: '700'}}>{i.duration} Months</span>
                                        </div>
                                        <hr/>
                                        <div className='homeproperty'>
                                            Country: {i.country} <br/>
                                            City: {i.city} <br/>
                                            Neighborhood: {i.neighborhood}
                                        </div>
                                        <div>
                                            Availability: {i.availability}
                                        </div>
                                        <hr/>
                                        <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center'}}>
                                            <div style={{  display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '80px'}}>
                                                <div onClick={()=> onLike(i.id)}>
                                                    <CDBIcon className="property-icon" icon="heart" />
                                                    <span>{i.likes || 0}</span>
                                                </div>
                                                {/* <div>
                                                    <CDBIcon className="property-icon" icon="comment" />
                                                    <span>{i.comments || 0}</span>
                                                </div> */}
                                                <Comments com={i.comments || 0} pro_id={i.id} getBack={getUser}/>
                                            </div>
                                            <span onClick={()=> onDelete(i.id)} className="agent" style={{backgroundColor: 'red', color: '#fff'}}>Delete</span>
                                            {/* <ViewPricing /> */}
                                        </div>
                                    
                                    </div>
                                </div>
                            </div>
                        ))}
                        </Row>
                    </Container>
                </Row>
                
            </div>
        </div>
    )
}
