import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Modal } from 'react-bootstrap';
import styled from 'styled-components';

export default function UserNavBar(){
  return(
    <Styles></Styles>
  )
}

const Styles = styled.div``;