import React, { useState, useEffect } from 'react';
import {Modal, Row, Col, Form, Button} from 'react-bootstrap';
import { CDBIcon } from "cdbreact";
import {getFromStorage} from './../utils/Storage'
import axios from './../utils/axios';
import '../pages/css/viewPricing.css';

import img2 from './../assets/imgs/2.jpg';

import { Alert } from 'rsuite';

function UploadPricing({ handleClosepricing, handleShowpricing, show, setShow, select, setSelect,
  showpricing, setShowpricing}) {
  
    const [bal, setBal] = useState(0);
    const user = getFromStorage('userToken');

  const uploadFunction = ()=>{
    handleClosepricing();
    setShow(true);
  }

  const buyNow = async(plan, dur)=>{
    let dat = {
      amount: plan,
      userID: JSON.parse(user).userId,
      reason: 'upload',
      duration: dur
    }
    
    if(bal >= plan){
      await axios.post('/users/action.php', dat).then((res)=>{
        console.log(res.data);
        if(res.data.status === 200){
          Alert.success('Plan activated');
          // getUser();
          setSelect(dur);
          uploadFunction();
        }else{
          Alert.error(res.data.message);
        }
    }).catch((err)=>{
        console.log(err);
    })
    }else{
      Alert.warning('Balance account is insuficient! Please recharge your account and retry')
    }
    
  }


  useEffect(()=>{
    // console.log('this is the user ',JSON.parse(user).userId);
    getUser();

    return ()=>{

    }
  }, []);

  const getUser = async()=>{
    await axios.post('/users/get_user.php', {userId: JSON.parse(user).userId}).then((res)=>{
        console.log(res.data);
        let userDat = res.data.user;
        if(res.data.status === 200){
           setBal(userDat.balance)
        }else{

        }
    }).catch((err)=>{
        console.log(err);
    })

  }
    
  return (
    <>
      {/* <span className="agentered" onClick={handleShowpricing}>Activate</span> */}

      <Modal
        show={showpricing}
        onHide={handleClosepricing}
        backdrop="static"
        keyboard={false}
        size="lg"
        scrollable={true}
        // fullscreen={true}
      >
        <Modal.Header closeButton>
          <Modal.Title>Upload pricing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <div className=''>
        <Row> 
            <div className='col-12 col-sm-6 col-lg-4 pt-4 pb-4'>
                <div className='dashCard pricingbase'>
                    {/* <img src={img2} width='100%' height={160} style={{position: 'relative', zIndex: 0}}/> */}
                    <div className='container pt-4 pb-4'>
                        <h4 style={{textAlign: 'center'}}>Basic</h4>
                        <p style={{textAlign: 'center', fontSize: 12}}>
                            By subscribing to this plan, your property will be visible for a period of 1 month
                        </p>
                        <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold', }}>XFA1000 / Month</p>
                        <span onClick={()=> buyNow(1000, 1)} className="agente base" style={{textAlign: 'center'}}>BUY NOW</span>
                        <span style={{textAlign: 'center', fontSize: 14, fontStyle: 'italic'}}>NB: your timer start immediately after the buy</span>
                    </div>
                </div>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 pt-4 pb-4'>
                <div className='dashCard pricingadvance'>
                    {/* <img src={img2} width='100%' height={160} style={{position: 'relative', zIndex: 0}}/> */}
                    <div className='container pt-4 pb-4'>
                        <h4 style={{textAlign: 'center'}}>Advanced</h4>
                        <p style={{textAlign: 'center', fontSize: 12}}>
                        By subscribing to this plan, your property will be visible for a period of 6 month
                        </p>
                        <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold', }}>XFA5000 / 6 Months</p>
                        <span  onClick={()=> buyNow(5000, 6)} className="agente advance" style={{textAlign: 'center'}}>BUY NOW</span>
                        <span style={{textAlign: 'center', fontSize: 14, fontStyle: 'italic'}}>NB: your timer start immediately after the buy</span>
                    </div>
                </div>
            </div>

            <div className='col-12 col-sm-6 col-lg-4 pt-4 pb-4'>
                <div className='dashCard pricingpremium'>
                    {/* <img src={img2} width='100%' height={160} style={{position: 'relative', zIndex: 0}}/> */}
                    <div className='container pt-4 pb-4'>
                        <h4 style={{textAlign: 'center'}}>Premium</h4>
                        <p style={{textAlign: 'center', fontSize: 12}}>
                        By subscribing to this plan, your property will be visible for a period of 12 month
                        </p>
                        <p style={{textAlign: 'center', fontSize: 22, fontWeight: 'bold', }}>XFA10000 / Year</p>
                        <span onClick={()=> buyNow(10000, 12)}  className="agente premium" style={{textAlign: 'center'}}>BUY NOW</span>
                        <span style={{textAlign: 'center', fontSize: 14, fontStyle: 'italic'}}>NB: your timer start immediately after the buy</span>
                    </div>
                </div>
            </div>
        </Row>
    </div>
        </Modal.Body>
        <Modal.Footer  style={{textAlign: 'left', width: '100%'}}>
          
          {bal ?
            <span>
            NB: The amount you select will be deducted from your account balance
            </span>
            :
            <span>
            PS: your account balance is not enough. 
            Please Recharge your account and come back
            </span>
          }
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default UploadPricing;